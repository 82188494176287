import React, {Component} from "react";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import BibliothequePlotly from "../BibliothequePlotly/BibliothequePlotly";
import {convertiUtsToDate} from "../GrapheXT/GrapheXT";

Date.prototype.getWeek = function () {
    let day_miliseconds = 86400000,
        onejan = new Date(this.getFullYear(), 0, 1, 0, 0, 0),
        onejan_day = (onejan.getDay() === 0) ? 7 : onejan.getDay(),
        days_for_next_monday = (8 - onejan_day),
        onejan_next_monday_time = onejan.getTime() + (days_for_next_monday * day_miliseconds),
        first_monday_year_time = (onejan_day > 1) ? onejan_next_monday_time : onejan.getTime(),
        this_date = new Date(this.getFullYear(), this.getMonth(), this.getDate(), 0, 0, 0),
        this_time = this_date.getTime(),
        days_from_first_monday = Math.floor((this_time - first_monday_year_time) / day_miliseconds),
        weeks_in_year = this.getFullYear() % 4 === 0 ? 53 : 52; // Ajuste para anos bissextos.

    // Verificar se a semana pertence ao próximo ano
    if (days_from_first_monday >= weeks_in_year * 7) {
        return 1;
    }

    return (days_from_first_monday >= 0 && days_from_first_monday < 364) ? Math.ceil((days_from_first_monday + 1) / 7) : 52;
}

const getWeekNumberForPlotlyDate = (plotlyDate) => {
    const listeDate = plotlyDate.split("-")
    let currentdate = new Date(listeDate[0], parseInt(listeDate[1]) - 1, listeDate[2])
    return currentdate.getWeek();
}

function dateDiff(date1Plotly, date2Plotly) {
    let date1 = new Date(date1Plotly + "T00:00:00Z");
    let date2 = new Date(date2Plotly + "T00:00:00Z");
    let tmp = date2.getTime() - date1.getTime();
    return Math.floor(tmp / (1000 * 60 * 60 * 24)); // Número de dias de diferença
}

class GraphesAvancementJourSemaineMois extends Component {
    state = {
        data: [],
        layout: {},
        dateSelectionne: "",
        valeurSelectionne: "",
        xAxisSelectionne: "",
        indSelectionne: "",
        prevDateSelectionne: "",
        prevXAxisSelectionne: ""
    }

    componentDidMount() {
        let data = {
            x: [
                "2021-06-01 01:00",
                "2021-06-01 02:00",
                "2021-06-01 03:00",
                "2021-06-01 04:00",
                "2021-06-01 05:00",
                "2021-06-01 06:00",
                "2021-06-01 07:00",
                "2021-06-01 08:00",
                "2021-06-01 09:00",
                "2021-06-01 10:00",
                "2021-06-01 11:00",
                "2021-06-01 12:00",
                "2021-06-01 13:00",
                "2021-06-01 14:00",
                "2021-06-01 15:00",
                "2021-06-01 16:00",
                "2021-06-01 17:00",
                "2021-06-01 18:00",
                "2021-06-01 19:00",
                "2021-06-01 20:00",
                "2021-06-01 23:00",
                "2021-06-02 00:00",
                "2021-06-02 01:00",
                "2021-06-02 02:00",
                "2021-06-02 03:00",
                "2021-06-02 04:00",
                "2021-06-02 05:00",
                "2021-06-02 06:00",
                "2021-06-02 07:00",
                "2021-06-02 08:00",
                "2021-06-02 09:00",
                "2021-06-02 10:00",
                "2021-06-02 11:00",
                "2021-06-02 12:00",
                "2021-06-02 13:00",
                "2021-06-02 14:00",
                "2021-06-02 15:00",
                "2021-06-02 16:00",
                "2021-06-02 17:00",
                "2021-06-02 18:00",
                "2021-06-02 19:00",
                "2021-06-02 20:00",
                "2021-06-02 21:00",
                "2021-06-02 22:00",
                "2021-06-02 23:00",
                "2021-06-03 00:00",
                "2021-06-03 01:00",
                "2021-06-03 02:00",
                "2021-06-03 03:00",
                "2021-06-03 04:00",
                "2021-06-03 05:00",
                "2021-06-03 06:00",
                "2021-06-03 07:00",
                "2021-06-03 08:00",
                "2021-06-03 09:00",
                "2021-06-03 10:00",
                "2021-06-03 12:00",
                "2021-06-03 13:00",
                "2021-06-03 14:00",
                "2021-06-03 15:00",
                "2021-06-03 16:00",
                "2021-06-03 17:00",
                "2021-06-03 18:00",
                "2021-06-03 19:00",
                "2021-06-03 20:00",
                "2021-06-03 21:00",
                "2021-06-03 22:00",
                "2021-06-03 23:00",
                "2021-06-04 00:00",
                "2021-06-04 01:00",
                "2021-06-04 02:00",
                "2021-06-04 03:00",
                "2021-06-04 04:00",
                "2021-06-04 05:00",
                "2021-06-04 06:00",
                "2021-06-04 07:00",
                "2021-06-04 08:00",
                "2021-06-04 09:00",
                "2021-06-04 10:00",
                "2021-06-04 11:00",
                "2021-06-04 12:00",
                "2021-06-04 13:00",
                "2021-06-04 14:00",
                "2021-06-04 15:00",
                "2021-06-04 16:00",
                "2021-06-04 17:00",
                "2021-06-04 18:00",
                "2021-06-04 19:00",
                "2021-06-04 20:00",
                "2021-06-04 21:00",
                "2021-06-04 22:00",
                "2021-06-04 23:00",
                "2021-06-05 00:00",
                "2021-06-05 01:00",
                "2021-06-05 02:00",
                "2021-06-05 03:00",
                "2021-06-05 04:00",
                "2021-06-05 05:00",
                "2021-06-05 06:00",
                "2021-06-05 07:00",
                "2021-06-05 08:00",
                "2021-06-05 09:00",
                "2021-06-05 10:00",
                "2021-06-05 11:00",
                "2021-06-05 12:00",
                "2021-06-05 13:00",
                "2021-06-05 14:00",
                "2021-06-05 15:00",
                "2021-06-05 16:00",
                "2021-06-05 17:00",
                "2021-06-05 18:00",
                "2021-06-05 19:00",
                "2021-06-05 20:00",
                "2021-06-05 21:00",
                "2021-06-05 22:00",
                "2021-06-05 23:00",
                "2021-06-06 00:00",
                "2021-06-06 01:00",
                "2021-06-06 02:00",
                "2021-06-06 03:00",
                "2021-06-06 04:00",
                "2021-06-06 05:00",
                "2021-06-06 06:00",
                "2021-06-06 07:00",
                "2021-06-06 08:00",
                "2021-06-06 09:00",
                "2021-06-06 10:00",
                "2021-06-06 11:00",
                "2021-06-06 12:00",
                "2021-06-06 13:00",
                "2021-06-06 14:00",
                "2021-06-06 15:00",
                "2021-06-06 16:00",
                "2021-06-06 17:00",
                "2021-06-06 18:00",
                "2021-06-06 19:00",
                "2021-06-06 20:00",
                "2021-06-06 21:00",
                "2021-06-06 22:00",
                "2021-06-06 23:00",
                "2021-06-07 00:00",
                "2021-06-07 01:00",
                "2021-06-07 02:00",
                "2021-06-07 03:00",
                "2021-06-07 04:00",
                "2021-06-07 05:00",
                "2021-06-07 06:00",
                "2021-06-07 07:00",
                "2021-06-07 08:00",
                "2021-06-07 09:00",
                "2021-06-07 10:00",
                "2021-06-07 11:00",
                "2021-06-07 12:00",
                "2021-06-07 13:00",
                "2021-06-07 14:00",
                "2021-06-07 15:00",
                "2021-06-07 16:00",
                "2021-06-07 17:00",
                "2021-06-07 18:00",
                "2021-06-07 19:00",
                "2021-06-07 20:00",
                "2021-06-07 21:00",
                "2021-06-07 22:00",
                "2021-06-07 23:00",
                "2021-06-08 00:00",
                "2021-06-08 01:00",
                "2021-06-08 02:00",
                "2021-06-08 03:00",
                "2021-06-08 04:00",
                "2021-06-08 05:00",
                "2021-06-08 06:00",
                "2021-06-08 07:00",
                "2021-06-08 08:00",
                "2021-06-08 09:00",
                "2021-06-08 10:00",
                "2021-06-08 11:00",
                "2021-06-08 12:00",
                "2021-06-08 13:00",
                "2021-06-08 14:00",
                "2021-06-08 15:00",
                "2021-06-08 16:00",
                "2021-06-08 17:00",
                "2021-06-08 18:00",
                "2021-06-08 19:00",
                "2021-06-08 20:00",
                "2021-06-08 21:00",
                "2021-06-08 22:00",
                "2021-06-08 23:00",
                "2021-06-09 00:00",
                "2021-06-09 01:00",
                "2021-06-09 02:00",
                "2021-06-09 03:00",
                "2021-06-09 04:00",
                "2021-06-09 05:00",
                "2021-06-09 06:00",
                "2021-06-09 07:00",
                "2021-06-09 08:00",
                "2021-06-09 09:00",
                "2021-06-09 10:00",
                "2021-06-09 11:00",
                "2021-06-09 12:00",
                "2021-06-09 13:00",
                "2021-06-09 14:00",
                "2021-06-09 15:00",
                "2021-06-09 16:00",
                "2021-06-09 17:00",
                "2021-06-09 18:00",
                "2021-06-09 19:00",
                "2021-06-09 20:00",
                "2021-06-09 21:00",
                "2021-06-09 22:00",
                "2021-06-09 23:00",
                "2021-06-10 00:00",
                "2021-06-10 01:00",
                "2021-06-10 02:00",
                "2021-06-10 03:00",
                "2021-06-10 04:00",
                "2021-06-10 05:00",
                "2021-06-10 06:00",
                "2021-06-10 07:00",
                "2021-06-10 08:00",
                "2021-06-10 09:00",
                "2021-06-10 10:00",
                "2021-06-10 11:00",
                "2021-06-10 12:00",
                "2021-06-10 13:00",
                "2021-06-10 14:00",
                "2021-06-10 15:00",
                "2021-06-10 16:00",
                "2021-06-10 17:00",
                "2021-06-10 18:00",
                "2021-06-10 19:00",
                "2021-06-10 20:00",
                "2021-06-10 21:00",
                "2021-06-10 22:00",
                "2021-06-10 23:00",
                "2021-06-11 00:00",
                "2021-06-11 01:00",
                "2021-06-11 02:00",
                "2021-06-11 03:00",
                "2021-06-11 04:00",
                "2021-06-11 05:00",
                "2021-06-11 06:00",
                "2021-06-11 07:00",
                "2021-06-11 08:00",
                "2021-06-11 09:00",
                "2021-06-11 10:00",
                "2021-06-11 11:00",
                "2021-06-11 12:00",
                "2021-06-11 13:00",
                "2021-06-11 14:00",
                "2021-06-11 15:00",
                "2021-06-11 16:00",
                "2021-06-11 17:00",
                "2021-06-11 18:00",
                "2021-06-11 19:00",
                "2021-06-11 20:00",
                "2021-06-11 21:00",
                "2021-06-11 22:00",
                "2021-06-11 23:00",
                "2021-06-12 00:00",
                "2021-06-12 01:00",
                "2021-06-12 02:00",
                "2021-06-12 03:00",
                "2021-06-12 04:00",
                "2021-06-12 05:00",
                "2021-06-12 06:00",
                "2021-06-12 07:00",
                "2021-06-12 08:00",
                "2021-06-12 09:00",
                "2021-06-12 10:00",
                "2021-06-12 11:00",
                "2021-06-12 12:00",
                "2021-06-12 13:00",
                "2021-06-12 14:00",
                "2021-06-12 15:00",
                "2021-06-12 16:00",
                "2021-06-12 17:00",
                "2021-06-12 18:00",
                "2021-06-12 19:00",
                "2021-06-12 20:00",
                "2021-06-12 21:00",
                "2021-06-12 22:00",
                "2021-06-12 23:00",
                "2021-06-13 00:00",
                "2021-06-13 01:00",
                "2021-06-13 02:00",
                "2021-06-13 03:00",
                "2021-06-13 04:00",
                "2021-06-13 05:00",
                "2021-06-13 06:00",
                "2021-06-13 07:00",
                "2021-06-13 08:00",
                "2021-06-13 09:00",
                "2021-06-13 10:00",
                "2021-06-13 11:00",
                "2021-06-13 12:00",
                "2021-06-13 13:00",
                "2021-06-13 14:00",
                "2021-06-13 15:00",
                "2021-06-13 16:00",
                "2021-06-13 17:00",
                "2021-06-13 18:00",
                "2021-06-13 19:00",
                "2021-06-13 20:00",
                "2021-06-13 21:00",
                "2021-06-13 22:00",
                "2021-06-13 23:00",
                "2021-06-14 00:00",
                "2021-06-14 01:00",
                "2021-06-14 02:00",
                "2021-06-14 03:00",
                "2021-06-14 04:00",
                "2021-06-14 05:00",
                "2021-06-14 06:00",
                "2021-06-14 07:00",
                "2021-06-14 08:00",
                "2021-06-14 09:00",
                "2021-06-14 10:00",
                "2021-06-14 11:00",
                "2021-06-14 12:00",
                "2021-06-14 13:00",
                "2021-06-14 14:00",
                "2021-06-14 15:00",
                "2021-06-14 16:00",
                "2021-06-14 17:00",
                "2021-06-14 18:00",
                "2021-06-14 19:00",
                "2021-06-14 20:00",
                "2021-06-14 21:00",
                "2021-06-14 22:00",
                "2021-06-14 23:00",
                "2021-06-15 00:00",
                "2021-06-15 01:00",
                "2021-06-15 02:00",
                "2021-06-15 03:00",
                "2021-06-15 04:00",
                "2021-06-15 05:00",
                "2021-06-15 06:00",
                "2021-06-15 07:00",
                "2021-06-15 08:00",
                "2021-06-15 09:00",
                "2021-06-15 10:00",
                "2021-06-15 11:00",
                "2021-06-15 12:00",
                "2021-06-15 13:00",
                "2021-06-15 14:00",
                "2021-06-15 15:00",
                "2021-06-15 16:00",
                "2021-06-15 17:00",
                "2021-06-15 18:00",
                "2021-06-15 19:00",
                "2021-06-15 20:00",
                "2021-06-15 21:00",
                "2021-06-15 22:00",
                "2021-06-15 23:00",
                "2021-06-16 00:00",
                "2021-06-16 01:00",
                "2021-06-16 02:00",
                "2021-06-16 03:00",
                "2021-06-16 04:00",
                "2021-06-16 05:00",
                "2021-06-16 06:00",
                "2021-06-16 07:00",
                "2021-06-16 08:00",
                "2021-06-16 09:00",
                "2021-06-16 10:00",
                "2021-06-16 11:00",
                "2021-06-16 12:00",
                "2021-06-16 13:00",
                "2021-06-16 14:00",
                "2021-06-16 15:00",
                "2021-06-16 16:00",
                "2021-06-16 17:00",
                "2021-06-16 18:00",
                "2021-06-16 19:00",
                "2021-06-16 20:00",
                "2021-06-16 21:00",
                "2021-06-16 22:00",
                "2021-06-16 23:00",
                "2021-06-17 00:00",
                "2021-06-17 01:00",
                "2021-06-17 02:00",
                "2021-06-17 03:00",
                "2021-06-17 04:00",
                "2021-06-17 05:00",
                "2021-06-17 06:00",
                "2021-06-17 07:00",
                "2021-06-17 08:00",
                "2021-06-17 09:00",
                "2021-06-17 10:00",
                "2021-06-17 11:00",
                "2021-06-17 12:00",
                "2021-06-17 13:00",
                "2021-06-17 14:00",
                "2021-06-17 15:00",
                "2021-06-17 16:00",
                "2021-06-17 17:00",
                "2021-06-17 18:00",
                "2021-06-17 19:00",
                "2021-06-17 20:00",
                "2021-06-17 21:00",
                "2021-06-17 22:00",
                "2021-06-17 23:00",
                "2021-06-18 00:00",
                "2021-06-18 01:00",
                "2021-06-18 02:00",
                "2021-06-18 03:00",
                "2021-06-18 04:00",
                "2021-06-18 05:00",
                "2021-06-18 06:00",
                "2021-06-18 07:00",
                "2021-06-18 08:00",
                "2021-06-18 09:00",
                "2021-06-18 10:00",
                "2021-06-18 11:00",
                "2021-06-18 12:00",
                "2021-06-18 13:00",
                "2021-06-18 14:00",
                "2021-06-18 15:00",
                "2021-06-18 16:00",
                "2021-06-18 17:00",
                "2021-06-18 18:00",
                "2021-06-18 19:00",
                "2021-06-18 20:00",
                "2021-06-18 21:00",
                "2021-06-18 22:00",
                "2021-06-18 23:00",
                "2021-06-19 00:00",
                "2021-06-19 01:00",
                "2021-06-19 02:00",
                "2021-06-19 03:00",
                "2021-06-19 04:00",
                "2021-06-19 05:00",
                "2021-06-19 06:00",
                "2021-06-19 07:00",
                "2021-06-19 08:00",
                "2021-06-20 00:00",
                "2021-06-20 01:00",
                "2021-06-20 02:00",
                "2021-06-20 03:00",
                "2021-06-20 04:00",
                "2021-06-20 05:00",
                "2021-06-20 06:00",
                "2021-06-20 07:00",
                "2021-06-20 08:00",
                "2021-06-20 09:00",
                "2021-06-20 10:00",
                "2021-06-20 11:00",
                "2021-06-20 12:00",
                "2021-06-20 13:00",
                "2021-06-20 14:00",
                "2021-06-20 15:00",
                "2021-06-20 16:00",
                "2021-06-20 17:00",
                "2021-06-20 18:00",
                "2021-06-20 19:00",
                "2021-06-20 20:00",
                "2021-06-20 21:00",
                "2021-06-20 22:00",
                "2021-06-20 23:00",
                "2021-06-21 00:00",
                "2021-06-21 01:00",
                "2021-06-21 02:00",
                "2021-06-21 03:00",
                "2021-06-21 04:00",
                "2021-06-21 05:00",
                "2021-06-21 06:00",
                "2021-06-21 07:00",
                "2021-06-21 08:00",
                "2021-06-21 09:00",
                "2021-06-21 10:00",
                "2021-06-21 11:00",
                "2021-06-21 12:00",
                "2021-06-21 13:00",
                "2021-06-21 14:00",
                "2021-06-21 15:00",
                "2021-06-21 16:00",
                "2021-06-21 17:00",
                "2021-06-21 18:00",
                "2021-06-21 19:00",
                "2021-06-21 20:00",
                "2021-06-21 21:00",
                "2021-06-21 22:00",
                "2021-06-21 23:00",
                "2021-06-22 00:00",
                "2021-06-22 01:00",
                "2021-06-22 02:00",
                "2021-06-22 03:00",
                "2021-06-22 04:00",
                "2021-06-22 05:00",
                "2021-06-22 06:00",
                "2021-06-22 07:00",
                "2021-06-22 08:00",
                "2021-06-22 09:00",
                "2021-06-22 10:00",
                "2021-06-22 11:00",
                "2021-06-22 12:00",
                "2021-06-22 13:00",
                "2021-06-22 14:00",
                "2021-06-22 15:00",
                "2021-06-22 16:00",
                "2021-06-22 17:00",
                "2021-06-22 18:00",
                "2021-06-22 19:00",
                "2021-06-22 20:00",
                "2021-06-22 21:00",
                "2021-06-22 22:00",
                "2021-06-22 23:00",
                "2021-06-23 00:00",
                "2021-06-23 01:00",
                "2021-06-23 02:00",
                "2021-06-23 03:00",
                "2021-06-23 04:00",
                "2021-06-23 05:00",
                "2021-06-23 06:00",
                "2021-06-23 07:00",
                "2021-06-23 08:00",
                "2021-06-23 09:00",
                "2021-06-23 10:00",
                "2021-06-23 11:00",
                "2021-06-23 12:00",
                "2021-06-23 13:00",
                "2021-06-23 14:00",
                "2021-06-23 15:00",
                "2021-06-23 16:00",
                "2021-06-23 17:00",
                "2021-06-23 18:00",
                "2021-06-23 19:00",
                "2021-06-23 20:00",
                "2021-06-23 21:00",
                "2021-06-23 22:00",
                "2021-06-23 23:00",
                "2021-06-24 00:00",
                "2021-06-24 01:00",
                "2021-06-24 02:00",
                "2021-06-24 03:00",
                "2021-06-24 04:00",
                "2021-06-24 05:00",
                "2021-06-24 06:00",
                "2021-06-24 07:00",
                "2021-06-24 08:00",
                "2021-06-24 09:00",
                "2021-06-24 10:00",
                "2021-06-24 11:00",
                "2021-06-24 12:00",
                "2021-06-24 13:00",
                "2021-06-24 14:00",
                "2021-06-24 15:00",
                "2021-06-24 16:00",
                "2021-06-24 17:00",
                "2021-06-24 18:00",
                "2021-06-24 19:00",
                "2021-06-24 20:00",
                "2021-06-24 21:00",
                "2021-06-24 22:00",
                "2021-06-24 23:00",
                "2021-06-25 00:00",
                "2021-06-25 01:00",
                "2021-06-25 02:00",
                "2021-06-25 03:00",
                "2021-06-25 04:00",
                "2021-06-25 05:00",
                "2021-06-25 06:00",
                "2021-06-25 07:00",
                "2021-06-25 08:00",
                "2021-06-25 09:00",
                "2021-06-25 10:00",
                "2021-06-25 11:00",
                "2021-06-25 12:00",
                "2021-06-25 13:00",
                "2021-06-25 14:00",
                "2021-06-25 15:00",
                "2021-06-25 16:00",
                "2021-06-25 17:00",
                "2021-06-25 18:00",
                "2021-06-25 19:00",
                "2021-06-25 20:00",
                "2021-06-25 21:00",
                "2021-06-25 22:00",
                "2021-06-25 23:00",
                "2021-06-26 00:00",
                "2021-06-26 01:00",
                "2021-06-26 02:00",
                "2021-06-26 03:00",
                "2021-06-26 04:00",
                "2021-06-26 05:00",
                "2021-06-26 06:00",
                "2021-06-26 07:00",
                "2021-06-26 08:00",
                "2021-06-26 09:00",
                "2021-06-26 10:00",
                "2021-06-26 11:00",
                "2021-06-26 12:00",
                "2021-06-26 13:00",
                "2021-06-26 14:00",
                "2021-06-26 15:00",
                "2021-06-26 16:00",
                "2021-06-26 17:00",
                "2021-06-26 18:00",
                "2021-06-26 19:00",
                "2021-06-26 20:00",
                "2021-06-26 21:00",
                "2021-06-26 22:00",
                "2021-06-26 23:00",
                "2021-06-27 00:00",
                "2021-06-27 01:00",
                "2021-06-27 02:00",
                "2021-06-27 03:00",
                "2021-06-27 04:00",
                "2021-06-27 05:00",
                "2021-06-27 06:00",
                "2021-06-27 07:00",
                "2021-06-27 08:00",
                "2021-06-27 09:00",
                "2021-06-27 10:00",
                "2021-06-27 11:00",
                "2021-06-27 12:00",
                "2021-06-27 13:00",
                "2021-06-27 14:00",
                "2021-06-27 15:00",
                "2021-06-27 16:00",
                "2021-06-27 17:00",
                "2021-06-27 18:00",
                "2021-06-27 19:00",
                "2021-06-27 20:00",
                "2021-06-27 21:00",
                "2021-06-27 22:00",
                "2021-06-27 23:00",
                "2021-06-28 00:00",
                "2021-06-28 01:00",
                "2021-06-28 02:00",
                "2021-06-28 03:00",
                "2021-06-28 04:00",
                "2021-06-28 05:00",
                "2021-06-28 06:00",
                "2021-06-28 07:00",
                "2021-06-28 08:00",
                "2021-06-28 09:00",
                "2021-06-28 10:00",
                "2021-06-28 11:00",
                "2021-06-28 12:00",
                "2021-06-28 13:00",
                "2021-06-28 14:00",
                "2021-06-28 15:00",
                "2021-06-28 16:00",
                "2021-06-28 17:00",
                "2021-06-28 18:00",
                "2021-06-28 19:00",
                "2021-06-28 20:00",
                "2021-06-28 21:00",
                "2021-06-28 22:00",
                "2021-06-28 23:00",
                "2021-06-29 00:00",
                "2021-06-29 01:00",
                "2021-06-29 02:00",
                "2021-06-29 03:00",
                "2021-06-29 04:00",
                "2021-06-29 05:00",
                "2021-06-29 06:00",
                "2021-06-29 07:00",
                "2021-06-29 08:00",
                "2021-06-29 09:00",
                "2021-06-29 10:00",
                "2021-06-29 11:00",
                "2021-06-29 12:00",
                "2021-06-29 13:00",
                "2021-06-29 14:00",
                "2021-06-29 15:00",
                "2021-06-29 16:00",
                "2021-06-29 17:00",
                "2021-06-29 18:00",
                "2021-06-29 19:00",
                "2021-06-29 20:00",
                "2021-06-29 21:00",
                "2021-06-29 22:00",
                "2021-06-29 23:00",
                "2021-06-30 00:00",
                "2021-06-30 01:00",
                "2021-06-30 02:00",
                "2021-06-30 03:00",
                "2021-06-30 04:00",
                "2021-06-30 05:00",
                "2021-06-30 06:00",
                "2021-06-30 07:00",
                "2021-06-30 08:00",
                "2021-06-30 09:00",
                "2021-06-30 10:00",
                "2021-06-30 11:00",
                "2021-06-30 12:00",
                "2021-06-30 13:00",
                "2021-06-30 14:00",
                "2021-06-30 15:00",
                "2021-06-30 16:00",
                "2021-06-30 17:00",
                "2021-06-30 18:00",
                "2021-06-30 20:00",
                "2021-06-30 21:00",
                "2021-06-30 22:00",
                "2021-06-30 23:00",
                "2021-07-01 00:00",
                "2021-07-01 01:00",
                "2021-07-01 02:00",
                "2021-07-01 03:00",
                "2021-07-01 04:00",
                "2021-07-01 05:00",
                "2021-07-01 06:00",
                "2021-07-01 07:00",
                "2021-07-01 08:00",
                "2021-07-01 09:00",
                "2021-07-01 10:00",
                "2021-07-01 11:00",
                "2021-07-01 12:00",
                "2021-07-01 13:00",
                "2021-07-01 14:00",
                "2021-07-01 15:00",
                "2021-07-01 16:00",
                "2021-07-01 17:00",
                "2021-07-01 18:00",
                "2021-07-01 19:00",
                "2021-07-01 20:00",
                "2021-07-01 21:00",
                "2021-07-01 22:00",
                "2021-07-01 23:00",
                "2021-07-02 00:00",
                "2021-07-02 01:00",
                "2021-07-02 02:00",
                "2021-07-02 03:00",
                "2021-07-02 04:00",
                "2021-07-02 05:00",
                "2021-07-02 06:00",
                "2021-07-02 07:00",
                "2021-07-02 08:00",
                "2021-07-02 09:00",
                "2021-07-02 10:00",
                "2021-07-02 11:00",
                "2021-07-02 12:00",
                "2021-07-02 13:00",
                "2021-07-02 14:00",
                "2021-07-02 15:00",
                "2021-07-02 16:00",
                "2021-07-02 17:00",
                "2021-07-02 18:00",
                "2021-07-02 19:00",
                "2021-07-02 20:00",
                "2021-07-02 21:00",
                "2021-07-02 22:00",
                "2021-07-02 23:00",
                "2021-07-03 00:00",
                "2021-07-03 01:00",
                "2021-07-03 02:00",
                "2021-07-03 03:00",
                "2021-07-03 04:00",
                "2021-07-03 05:00",
                "2021-07-03 06:00",
                "2021-07-03 07:00",
                "2021-07-03 08:00",
                "2021-07-03 09:00",
                "2021-07-03 10:00",
                "2021-07-03 11:00",
                "2021-07-03 12:00",
                "2021-07-03 13:00",
                "2021-07-03 14:00",
                "2021-07-03 15:00",
                "2021-07-03 16:00",
                "2021-07-03 17:00",
                "2021-07-03 18:00",
                "2021-07-03 19:00",
                "2021-07-03 20:00",
                "2021-07-03 21:00",
                "2021-07-03 22:00",
                "2021-07-03 23:00",
                "2021-07-04 00:00",
                "2021-07-04 01:00",
                "2021-07-04 02:00",
                "2021-07-04 03:00",
                "2021-07-04 04:00",
                "2021-07-04 05:00",
                "2021-07-04 06:00",
                "2021-07-04 07:00",
                "2021-07-04 08:00",
                "2021-07-04 09:00",
                "2021-07-04 10:00",
                "2021-07-04 20:00",
                "2021-07-04 21:00",
                "2021-07-04 22:00",
                "2021-07-04 23:00",
                "2021-07-05 00:00",
                "2021-07-05 01:00",
                "2021-07-05 02:00",
                "2021-07-05 03:00",
                "2021-07-05 04:00",
                "2021-07-05 05:00",
                "2021-07-05 06:00",
                "2021-07-05 07:00",
                "2021-07-05 08:00",
                "2021-07-05 09:00",
                "2021-07-05 10:00",
                "2021-07-05 11:00",
                "2021-07-05 12:00",
                "2021-07-05 13:00",
                "2021-07-05 14:00",
                "2021-07-05 15:00",
                "2021-07-05 16:00",
                "2021-07-05 17:00",
                "2021-07-05 18:00",
                "2021-07-05 19:00",
                "2021-07-05 20:00",
                "2021-07-05 21:00",
                "2021-07-05 22:00",
                "2021-07-05 23:00",
                "2021-07-06 00:00",
                "2021-07-06 01:00",
                "2021-07-06 02:00",
                "2021-07-06 03:00",
                "2021-07-06 04:00",
                "2021-07-06 05:00",
                "2021-07-06 06:00",
                "2021-07-06 07:00",
                "2021-07-06 08:00",
                "2021-07-06 09:00",
                "2021-07-06 10:00",
                "2021-07-06 11:00",
                "2021-07-06 12:00",
                "2021-07-06 13:00",
                "2021-07-06 14:00",
                "2021-07-06 15:00",
                "2021-07-06 16:00",
                "2021-07-06 17:00",
                "2021-07-06 18:00",
                "2021-07-06 19:00",
                "2021-07-06 20:00",
                "2021-07-06 21:00",
                "2021-07-06 22:00",
                "2021-07-06 23:00",
                "2021-07-07 00:00",
                "2021-07-07 01:00",
                "2021-07-07 02:00",
                "2021-07-07 03:00",
                "2021-07-07 04:00",
                "2021-07-07 05:00",
                "2021-07-07 06:00",
                "2021-07-07 07:00",
                "2021-07-07 08:00",
                "2021-07-07 09:00",
                "2021-07-07 10:00",
                "2021-07-07 11:00",
                "2021-07-07 12:00",
                "2021-07-07 13:00",
                "2021-07-07 14:00",
                "2021-07-07 15:00",
                "2021-07-07 16:00",
                "2021-07-07 17:00",
                "2021-07-07 18:00",
                "2021-07-07 19:00",
                "2021-07-07 20:00",
                "2021-07-07 21:00",
                "2021-07-07 22:00",
                "2021-07-07 23:00",
                "2021-07-08 00:00",
                "2021-07-08 01:00",
                "2021-07-08 02:00",
                "2021-07-08 03:00",
                "2021-07-08 04:00",
                "2021-07-08 05:00",
                "2021-07-08 06:00",
                "2021-07-08 07:00",
                "2021-07-08 08:00",
                "2021-07-08 09:00",
                "2021-07-08 10:00",
                "2021-07-08 11:00",
                "2021-07-08 12:00",
                "2021-07-08 13:00",
                "2021-07-08 14:00",
                "2021-07-08 15:00",
                "2021-07-08 16:00",
                "2021-07-08 17:00",
                "2021-07-08 18:00",
                "2021-07-08 19:00",
                "2021-07-08 20:00",
                "2021-07-08 21:00",
                "2021-07-08 22:00",
                "2021-07-08 23:00",
                "2021-07-09 00:00",
                "2021-07-09 01:00",
                "2021-07-09 02:00",
                "2021-07-09 03:00",
                "2021-07-09 04:00",
                "2021-07-09 05:00",
                "2021-07-09 06:00",
                "2021-07-09 07:00",
                "2021-07-09 08:00",
                "2021-07-09 09:00",
                "2021-07-09 10:00",
                "2021-07-09 11:00",
                "2021-07-09 12:00",
                "2021-07-09 13:00",
                "2021-07-09 14:00",
                "2021-07-09 15:00",
                "2021-07-09 16:00",
                "2021-07-09 17:00",
                "2021-07-09 18:00",
                "2021-07-09 19:00",
                "2021-07-09 20:00",
                "2021-07-09 21:00",
                "2021-07-09 22:00",
                "2021-07-09 23:00",
                "2021-07-10 00:00",
                "2021-07-10 01:00",
                "2021-07-10 02:00",
                "2021-07-10 03:00",
                "2021-07-10 04:00",
                "2021-07-10 05:00",
                "2021-07-10 06:00",
                "2021-07-10 07:00",
                "2021-07-10 08:00",
                "2021-07-10 09:00",
                "2021-07-10 10:00",
                "2021-07-10 11:00",
                "2021-07-10 12:00",
                "2021-07-10 13:00",
                "2021-07-10 14:00",
                "2021-07-10 15:00",
                "2021-07-10 16:00",
                "2021-07-10 17:00",
                "2021-07-10 18:00",
                "2021-07-10 19:00",
                "2021-07-10 20:00",
                "2021-07-10 21:00",
                "2021-07-10 22:00",
                "2021-07-10 23:00",
                "2021-07-11 00:00",
                "2021-07-11 01:00",
                "2021-07-11 02:00",
                "2021-07-11 03:00",
                "2021-07-11 04:00",
                "2021-07-11 05:00",
                "2021-07-11 06:00",
                "2021-07-11 07:00",
                "2021-07-11 08:00",
                "2021-07-11 09:00",
                "2021-07-11 10:00",
                "2021-07-11 11:00",
                "2021-07-11 12:00",
                "2021-07-11 13:00",
                "2021-07-11 14:00",
                "2021-07-11 15:00",
                "2021-07-11 16:00",
                "2021-07-11 17:00",
                "2021-07-11 18:00",
                "2021-07-11 19:00",
                "2021-07-11 20:00",
                "2021-07-11 21:00",
                "2021-07-11 22:00",
                "2021-07-11 23:00",
                "2021-07-12 00:00",
                "2021-07-12 01:00",
                "2021-07-12 02:00",
                "2021-07-12 03:00",
                "2021-07-12 04:00",
                "2021-07-12 05:00",
                "2021-07-12 06:00",
                "2021-07-12 07:00",
                "2021-07-12 08:00",
                "2021-07-12 09:00",
                "2021-07-12 10:00",
                "2021-07-12 11:00",
                "2021-07-12 12:00",
                "2021-07-12 13:00",
                "2021-07-12 14:00",
                "2021-07-12 15:00",
                "2021-07-12 16:00",
                "2021-07-12 17:00",
                "2021-07-12 18:00",
                "2021-07-12 19:00",
                "2021-07-12 20:00",
                "2021-07-12 21:00",
                "2021-07-12 22:00",
                "2021-07-12 23:00",
                "2021-07-13 00:00",
                "2021-07-13 01:00",
                "2021-07-13 02:00",
                "2021-07-13 03:00",
                "2021-07-13 04:00",
                "2021-07-13 05:00",
                "2021-07-13 06:00",
                "2021-07-13 07:00",
                "2021-07-13 08:00",
                "2021-07-13 09:00",
                "2021-07-13 10:00",
                "2021-07-13 11:00",
                "2021-07-13 12:00",
                "2021-07-13 13:00",
                "2021-07-13 14:00",
                "2021-07-13 15:00",
                "2021-07-13 16:00",
                "2021-07-13 17:00",
                "2021-07-13 18:00",
                "2021-07-13 19:00",
                "2021-07-13 20:00",
                "2021-07-13 21:00",
                "2021-07-13 22:00",
                "2021-07-13 23:00",
                "2021-07-14 00:00",
                "2021-07-14 01:00",
                "2021-07-14 02:00",
                "2021-07-14 03:00",
                "2021-07-14 04:00",
                "2021-07-14 05:00",
                "2021-07-14 06:00",
                "2021-07-14 07:00",
                "2021-07-14 08:00",
                "2021-07-14 09:00",
                "2021-07-14 10:00",
                "2021-07-14 11:00",
                "2021-07-14 12:00",
                "2021-07-14 13:00",
                "2021-07-14 14:00",
                "2021-07-14 15:00",
                "2021-07-14 16:00",
                "2021-07-14 17:00",
                "2021-07-14 18:00",
                "2021-07-14 19:00",
                "2021-07-14 20:00",
                "2021-07-14 21:00",
                "2021-07-14 22:00",
                "2021-07-14 23:00",
                "2021-07-15 00:00",
                "2021-07-15 01:00",
                "2021-07-15 02:00",
                "2021-07-15 03:00",
                "2021-07-15 04:00",
                "2021-07-15 05:00",
                "2021-07-15 06:00",
                "2021-07-15 07:00",
                "2021-07-15 08:00",
                "2021-07-15 09:00",
                "2021-07-15 10:00",
                "2021-07-15 11:00",
                "2021-07-15 12:00",
                "2021-07-15 13:00",
                "2021-07-15 14:00",
                "2021-07-15 15:00",
                "2021-07-15 17:00",
                "2021-07-15 18:00",
                "2021-07-15 19:00",
                "2021-07-15 20:00",
                "2021-07-15 21:00",
                "2021-07-15 22:00",
                "2021-07-15 23:00",
                "2021-07-16 00:00",
                "2021-07-16 01:00",
                "2021-07-16 02:00",
                "2021-07-16 03:00",
                "2021-07-16 04:00",
                "2021-07-16 05:00",
                "2021-07-16 06:00",
                "2021-07-16 07:00",
                "2021-07-16 08:00",
                "2021-07-16 09:00",
                "2021-07-16 10:00",
                "2021-07-16 11:00",
                "2021-07-16 12:00",
                "2021-07-16 13:00",
                "2021-07-16 14:00",
                "2021-07-16 15:00",
                "2021-07-16 16:00",
                "2021-07-16 17:00",
                "2021-07-16 18:00",
                "2021-07-16 19:00",
                "2021-07-16 20:00",
                "2021-07-16 21:00",
                "2021-07-16 22:00",
                "2021-07-16 23:00",
                "2021-07-17 00:00",
                "2021-07-17 01:00",
                "2021-07-17 02:00",
                "2021-07-17 03:00",
                "2021-07-17 04:00",
                "2021-07-17 05:00",
                "2021-07-17 06:00",
                "2021-07-17 07:00",
                "2021-07-17 08:00",
                "2021-07-17 09:00",
                "2021-07-17 10:00",
                "2021-07-17 11:00",
                "2021-07-17 12:00",
                "2021-07-17 13:00",
                "2021-07-17 14:00",
                "2021-07-17 15:00",
                "2021-07-17 16:00",
                "2021-07-17 17:00",
                "2021-07-17 18:00",
                "2021-07-17 19:00",
                "2021-07-17 20:00",
                "2021-07-17 21:00",
                "2021-07-17 22:00",
                "2021-07-17 23:00",
                "2021-07-18 00:00",
                "2021-07-18 01:00",
                "2021-07-18 02:00",
                "2021-07-18 03:00",
                "2021-07-18 04:00",
                "2021-07-18 05:00",
                "2021-07-18 06:00",
                "2021-07-18 07:00",
                "2021-07-18 08:00",
                "2021-07-18 09:00",
                "2021-07-18 10:00",
                "2021-07-18 11:00",
                "2021-07-18 12:00",
                "2021-07-18 13:00",
                "2021-07-18 14:00",
                "2021-07-18 15:00",
                "2021-07-18 16:00",
                "2021-07-18 17:00",
                "2021-07-18 18:00",
                "2021-07-18 19:00",
                "2021-07-18 20:00",
                "2021-07-18 21:00",
                "2021-07-18 22:00",
                "2021-07-18 23:00",
                "2021-07-19 00:00",
                "2021-07-19 01:00",
                "2021-07-19 02:00",
                "2021-07-19 03:00",
                "2021-07-19 04:00",
                "2021-07-19 05:00",
                "2021-07-19 06:00",
                "2021-07-19 07:00",
                "2021-07-19 08:00",
                "2021-07-19 09:00",
                "2021-07-19 10:00",
                "2021-07-19 11:00",
                "2021-07-19 12:00",
                "2021-07-19 13:00",
                "2021-07-19 14:00",
                "2021-07-19 15:00",
                "2021-07-19 16:00",
                "2021-07-19 17:00",
                "2021-07-19 18:00",
                "2021-07-19 19:00",
                "2021-07-19 20:00",
                "2021-07-19 21:00",
                "2021-07-19 22:00",
                "2021-07-19 23:00",
                "2021-07-20 00:00",
                "2021-07-20 01:00",
                "2021-07-20 02:00",
                "2021-07-20 03:00",
                "2021-07-20 04:00",
                "2021-07-20 05:00",
                "2021-07-20 06:00",
                "2021-07-20 07:00",
                "2021-07-20 08:00",
                "2021-07-20 09:00",
                "2021-07-20 10:00",
                "2021-07-20 11:00",
                "2021-07-20 12:00",
                "2021-07-20 13:00",
                "2021-07-20 14:00",
                "2021-07-20 15:00",
                "2021-07-20 16:00",
                "2021-07-20 17:00",
                "2021-07-20 18:00",
                "2021-07-20 19:00",
                "2021-07-20 20:00",
                "2021-07-20 21:00",
                "2021-07-20 22:00",
                "2021-07-20 23:00",
                "2021-07-21 00:00",
                "2021-07-21 01:00",
                "2021-07-21 02:00",
                "2021-07-21 03:00",
                "2021-07-21 04:00",
                "2021-07-21 05:00",
                "2021-07-21 06:00",
                "2021-07-21 07:00",
                "2021-07-21 08:00",
                "2021-07-21 09:00",
                "2021-07-21 10:00",
                "2021-07-21 11:00",
                "2021-07-21 12:00",
                "2021-07-21 13:00",
                "2021-07-21 14:00",
                "2021-07-21 15:00",
                "2021-07-21 16:00",
                "2021-07-21 17:00",
                "2021-07-21 18:00",
                "2021-07-21 19:00",
                "2021-07-21 20:00",
                "2021-07-21 21:00",
                "2021-07-21 22:00",
                "2021-07-21 23:00",
                "2021-07-22 00:00",
                "2021-07-22 01:00",
                "2021-07-22 02:00",
                "2021-07-22 03:00",
                "2021-07-22 04:00",
                "2021-07-22 05:00",
                "2021-07-22 06:00",
                "2021-07-22 07:00",
                "2021-07-22 08:00",
                "2021-07-22 09:00",
                "2021-07-22 10:00",
                "2021-07-22 11:00",
                "2021-07-22 12:00",
                "2021-07-22 13:00",
                "2021-07-22 14:00",
                "2021-07-22 15:00",
                "2021-07-22 16:00",
                "2021-07-22 17:00",
                "2021-07-22 18:00",
                "2021-07-22 19:00",
                "2021-07-22 20:00",
                "2021-07-22 21:00",
                "2021-07-22 22:00",
                "2021-07-22 23:00",
                "2021-07-23 00:00",
                "2021-07-23 01:00",
                "2021-07-23 02:00",
                "2021-07-23 03:00",
                "2021-07-23 04:00",
                "2021-07-23 05:00",
                "2021-07-23 06:00",
                "2021-07-23 07:00",
                "2021-07-23 08:00",
                "2021-07-23 09:00",
                "2021-07-23 10:00",
                "2021-07-23 11:00",
                "2021-07-23 12:00",
                "2021-07-23 13:00",
                "2021-07-23 14:00",
                "2021-07-23 15:00",
                "2021-07-23 16:00",
                "2021-07-23 17:00",
                "2021-07-23 18:00",
                "2021-07-23 19:00",
                "2021-07-23 20:00",
                "2021-07-23 21:00",
                "2021-07-23 22:00",
                "2021-07-23 23:00",
                "2021-07-24 00:00",
                "2021-07-24 01:00",
                "2021-07-24 02:00",
                "2021-07-24 03:00",
                "2021-07-24 04:00",
                "2021-07-24 05:00",
                "2021-07-24 06:00",
                "2021-07-24 07:00",
                "2021-07-24 08:00",
                "2021-07-24 09:00",
                "2021-07-24 10:00",
                "2021-07-24 11:00",
                "2021-07-24 12:00",
                "2021-07-24 13:00",
                "2021-07-24 14:00",
                "2021-07-24 15:00",
                "2021-07-24 16:00",
                "2021-07-24 17:00",
                "2021-07-24 18:00",
                "2021-07-24 19:00",
                "2021-07-24 20:00",
                "2021-07-24 21:00",
                "2021-07-24 22:00",
                "2021-07-24 23:00",
                "2021-07-25 00:00",
                "2021-07-25 01:00",
                "2021-07-25 02:00",
                "2021-07-25 03:00",
                "2021-07-25 04:00",
                "2021-07-25 05:00",
                "2021-07-25 06:00",
                "2021-07-25 07:00",
                "2021-07-25 08:00",
                "2021-07-25 09:00",
                "2021-07-25 10:00",
                "2021-07-25 11:00",
                "2021-07-25 12:00",
                "2021-07-25 13:00",
                "2021-07-25 14:00",
                "2021-07-25 15:00",
                "2021-07-25 16:00",
                "2021-07-25 17:00",
                "2021-07-25 18:00",
                "2021-07-25 19:00",
                "2021-07-25 20:00",
                "2021-07-25 21:00",
                "2021-07-25 22:00",
                "2021-07-25 23:00",
                "2021-07-26 00:00",
                "2021-07-26 01:00",
                "2021-07-26 02:00",
                "2021-07-26 03:00",
                "2021-07-26 04:00",
                "2021-07-26 05:00",
                "2021-07-26 06:00",
                "2021-07-26 07:00",
                "2021-07-26 08:00",
                "2021-07-26 09:00",
                "2021-07-26 10:00",
                "2021-07-26 11:00",
                "2021-07-26 12:00",
                "2021-07-26 13:00",
                "2021-07-26 14:00",
                "2021-07-26 15:00",
                "2021-07-26 16:00",
                "2021-07-26 17:00",
                "2021-07-26 18:00",
                "2021-07-26 19:00",
                "2021-07-26 20:00",
                "2021-07-26 21:00",
                "2021-07-26 22:00",
                "2021-07-26 23:00",
                "2021-07-27 00:00",
                "2021-07-27 01:00",
                "2021-07-27 02:00",
                "2021-07-27 03:00",
                "2021-07-27 04:00",
                "2021-07-27 05:00",
                "2021-07-27 06:00",
                "2021-07-27 07:00",
                "2021-07-27 08:00",
                "2021-07-27 09:00",
                "2021-07-27 10:00",
                "2021-07-27 11:00",
                "2021-07-27 12:00",
                "2021-07-27 13:00",
                "2021-07-27 14:00",
                "2021-07-27 15:00",
                "2021-07-27 16:00",
                "2021-07-27 17:00",
                "2021-07-27 18:00",
                "2021-07-27 19:00",
                "2021-07-27 20:00",
                "2021-07-27 21:00",
                "2021-07-27 22:00",
                "2021-07-27 23:00",
                "2021-07-28 00:00",
                "2021-07-28 01:00",
                "2021-07-28 02:00",
                "2021-07-28 03:00",
                "2021-07-28 04:00",
                "2021-07-28 05:00",
                "2021-07-28 06:00",
                "2021-07-28 07:00",
                "2021-07-28 08:00",
                "2021-07-28 09:00",
                "2021-07-28 10:00",
                "2021-07-28 11:00",
                "2021-07-28 12:00",
                "2021-07-28 13:00",
                "2021-07-28 14:00",
                "2021-07-28 15:00",
                "2021-07-28 16:00",
                "2021-07-28 17:00",
                "2021-07-28 18:00",
                "2021-07-28 19:00",
                "2021-07-28 20:00",
                "2021-07-28 21:00",
                "2021-07-28 22:00",
                "2021-07-28 23:00",
                "2021-07-29 00:00",
                "2021-07-29 01:00",
                "2021-07-29 02:00",
                "2021-07-29 03:00",
                "2021-07-29 04:00",
                "2021-07-29 05:00",
                "2021-07-29 06:00",
                "2021-07-29 07:00",
                "2021-07-29 08:00",
                "2021-07-29 09:00",
                "2021-07-29 10:00",
                "2021-07-29 11:00",
                "2021-07-29 12:00",
                "2021-07-29 13:00",
                "2021-07-29 14:00",
                "2021-07-29 15:00",
                "2021-07-29 16:00",
                "2021-07-29 17:00",
                "2021-07-29 18:00",
                "2021-07-29 19:00",
                "2021-07-29 20:00",
                "2021-07-29 21:00",
                "2021-07-29 22:00",
                "2021-07-29 23:00",
                "2021-07-30 00:00",
                "2021-07-30 01:00",
                "2021-07-30 02:00",
                "2021-07-30 03:00",
                "2021-07-30 04:00",
                "2021-07-30 05:00",
                "2021-07-30 06:00",
                "2021-07-30 07:00",
                "2021-07-30 08:00",
                "2021-07-30 09:00",
                "2021-07-30 10:00",
                "2021-07-30 11:00",
                "2021-07-30 12:00",
                "2021-07-30 13:00",
                "2021-07-30 14:00",
                "2021-07-30 15:00",
                "2021-07-30 16:00",
                "2021-07-30 17:00",
                "2021-07-30 18:00",
                "2021-07-30 19:00",
                "2021-07-30 20:00",
                "2021-07-30 21:00",
                "2021-07-30 22:00",
                "2021-07-30 23:00",
                "2021-07-31 00:00",
                "2021-07-31 01:00",
                "2021-07-31 02:00",
                "2021-07-31 03:00",
                "2021-07-31 04:00",
                "2021-07-31 05:00",
                "2021-07-31 06:00",
                "2021-07-31 07:00",
                "2021-07-31 08:00",
                "2021-07-31 09:00",
                "2021-07-31 10:00",
                "2021-07-31 11:00",
                "2021-07-31 12:00",
                "2021-07-31 13:00",
                "2021-07-31 14:00",
                "2021-07-31 15:00",
                "2021-07-31 16:00",
                "2021-07-31 17:00",
                "2021-07-31 18:00",
                "2021-07-31 19:00",
                "2021-07-31 20:00",
                "2021-07-31 21:00",
                "2021-07-31 22:00",
                "2021-07-31 23:00",
                "2021-08-01 00:00",
                "2021-08-01 01:00",
                "2021-08-01 02:00",
                "2021-08-01 03:00",
                "2021-08-01 04:00",
                "2021-08-01 05:00",
                "2021-08-01 06:00",
                "2021-08-01 07:00",
                "2021-08-01 08:00",
                "2021-08-01 09:00",
                "2021-08-01 10:00",
                "2021-08-01 11:00",
                "2021-08-01 12:00",
                "2021-08-01 13:00",
                "2021-08-01 14:00",
                "2021-08-01 15:00",
                "2021-08-01 16:00",
                "2021-08-01 17:00",
                "2021-08-01 18:00",
                "2021-08-01 19:00",
                "2021-08-01 20:00",
                "2021-08-01 21:00",
                "2021-08-01 22:00",
                "2021-08-01 23:00",
                "2021-08-02 00:00",
                "2021-08-02 01:00",
                "2021-08-02 02:00",
                "2021-08-02 03:00",
                "2021-08-02 04:00",
                "2021-08-02 05:00",
                "2021-08-02 06:00",
                "2021-08-02 07:00",
                "2021-08-02 08:00",
                "2021-08-02 09:00",
                "2021-08-02 10:00",
                "2021-08-02 11:00",
                "2021-08-02 12:00",
                "2021-08-02 13:00",
                "2021-08-02 14:00",
                "2021-08-02 15:00",
                "2021-08-02 16:00",
                "2021-08-02 17:00",
                "2021-08-02 18:00",
                "2021-08-02 19:00",
                "2021-08-02 20:00",
                "2021-08-02 21:00",
                "2021-08-02 22:00",
                "2021-08-02 23:00",
                "2021-08-03 00:00",
                "2021-08-03 01:00",
                "2021-08-03 02:00",
                "2021-08-03 03:00",
                "2021-08-03 04:00",
                "2021-08-03 05:00",
                "2021-08-03 06:00",
                "2021-08-03 07:00",
                "2021-08-03 08:00",
                "2021-08-03 09:00",
                "2021-08-03 10:00",
                "2021-08-03 11:00",
                "2021-08-03 12:00",
                "2021-08-03 13:00",
                "2021-08-03 14:00",
                "2021-08-03 15:00",
                "2021-08-03 16:00",
                "2021-08-03 17:00",
                "2021-08-03 18:00",
                "2021-08-03 19:00",
                "2021-08-03 20:00",
                "2021-08-03 21:00",
                "2021-08-03 22:00",
                "2021-08-03 23:00",
                "2021-08-04 00:00",
                "2021-08-04 01:00",
                "2021-08-04 02:00",
                "2021-08-04 03:00",
                "2021-08-04 04:00",
                "2021-08-04 05:00",
                "2021-08-04 06:00",
                "2021-08-04 07:00",
                "2021-08-04 08:00",
                "2021-08-04 09:00",
                "2021-08-04 10:00",
                "2021-08-04 20:00",
                "2021-08-04 21:00",
                "2021-08-04 22:00",
                "2021-08-04 23:00",
                "2021-08-05 00:00",
                "2021-08-05 01:00",
                "2021-08-05 02:00",
                "2021-08-05 03:00",
                "2021-08-05 04:00",
                "2021-08-05 05:00",
                "2021-08-05 06:00",
                "2021-08-05 07:00",
                "2021-08-05 08:00",
                "2021-08-05 09:00",
                "2021-08-05 10:00",
                "2021-08-05 11:00",
                "2021-08-05 12:00",
                "2021-08-05 13:00",
                "2021-08-05 14:00",
                "2021-08-05 15:00",
                "2021-08-05 16:00",
                "2021-08-05 17:00",
                "2021-08-05 18:00",
                "2021-08-05 19:00",
                "2021-08-05 20:00",
                "2021-08-05 21:00",
                "2021-08-05 22:00",
                "2021-08-05 23:00",
                "2021-08-06 00:00",
                "2021-08-06 01:00",
                "2021-08-06 02:00",
                "2021-08-06 03:00",
                "2021-08-06 04:00",
                "2021-08-06 05:00",
                "2021-08-06 06:00",
                "2021-08-06 07:00",
                "2021-08-06 08:00",
                "2021-08-06 09:00",
                "2021-08-06 10:00",
                "2021-08-06 11:00",
                "2021-08-06 12:00",
                "2021-08-06 13:00",
                "2021-08-06 14:00",
                "2021-08-06 15:00",
                "2021-08-06 16:00",
                "2021-08-06 17:00",
                "2021-08-06 18:00",
                "2021-08-06 19:00",
                "2021-08-06 20:00",
                "2021-08-06 21:00",
                "2021-08-06 22:00",
                "2021-08-06 23:00",
                "2021-08-07 00:00",
                "2021-08-07 01:00",
                "2021-08-07 02:00",
                "2021-08-07 03:00",
                "2021-08-07 04:00",
                "2021-08-07 05:00",
                "2021-08-07 06:00",
                "2021-08-07 07:00",
                "2021-08-07 08:00",
                "2021-08-07 09:00",
                "2021-08-07 10:00",
                "2021-08-07 11:00",
                "2021-08-07 12:00",
                "2021-08-07 13:00",
                "2021-08-07 14:00",
                "2021-08-07 15:00",
                "2021-08-07 16:00",
                "2021-08-07 17:00",
                "2021-08-07 18:00",
                "2021-08-07 19:00",
                "2021-08-07 20:00",
                "2021-08-07 21:00",
                "2021-08-07 22:00",
                "2021-08-07 23:00",
                "2021-08-08 00:00",
                "2021-08-08 01:00",
                "2021-08-08 02:00",
                "2021-08-08 03:00",
                "2021-08-08 04:00",
                "2021-08-08 05:00",
                "2021-08-08 06:00",
                "2021-08-08 07:00",
                "2021-08-08 08:00",
                "2021-08-08 09:00",
                "2021-08-08 10:00",
                "2021-08-08 11:00",
                "2021-08-08 12:00",
                "2021-08-08 13:00",
                "2021-08-08 14:00",
                "2021-08-08 15:00",
                "2021-08-08 16:00",
                "2021-08-08 17:00",
                "2021-08-08 18:00",
                "2021-08-08 19:00",
                "2021-08-08 20:00",
                "2021-08-08 21:00",
                "2021-08-08 22:00",
                "2021-08-08 23:00",
                "2021-08-09 00:00",
                "2021-08-09 01:00",
                "2021-08-09 02:00",
                "2021-08-09 03:00",
                "2021-08-09 04:00",
                "2021-08-09 05:00",
                "2021-08-09 06:00",
                "2021-08-09 07:00",
                "2021-08-09 08:00",
                "2021-08-09 09:00",
                "2021-08-09 10:00",
                "2021-08-09 11:00",
                "2021-08-09 12:00",
                "2021-08-09 13:00",
                "2021-08-09 14:00",
                "2021-08-09 15:00",
                "2021-08-09 16:00",
                "2021-08-09 17:00",
                "2021-08-09 18:00",
                "2021-08-09 19:00",
                "2021-08-09 20:00",
                "2021-08-09 21:00",
                "2021-08-09 22:00",
                "2021-08-09 23:00",
                "2021-08-10 00:00",
                "2021-08-10 01:00",
                "2021-08-10 02:00",
                "2021-08-10 03:00",
                "2021-08-10 04:00",
                "2021-08-10 05:00",
                "2021-08-10 06:00",
                "2021-08-10 07:00",
                "2021-08-10 08:00",
                "2021-08-10 09:00",
                "2021-08-10 10:00",
                "2021-08-10 11:00",
                "2021-08-10 12:00",
                "2021-08-10 13:00",
                "2021-08-10 14:00",
                "2021-08-10 15:00",
                "2021-08-10 16:00",
                "2021-08-10 17:00",
                "2021-08-10 18:00",
                "2021-08-10 19:00",
                "2021-08-10 20:00",
                "2021-08-10 21:00",
                "2021-08-10 22:00",
                "2021-08-10 23:00",
                "2021-08-11 00:00",
                "2021-08-11 01:00",
                "2021-08-11 02:00",
                "2021-08-11 03:00",
                "2021-08-11 04:00",
                "2021-08-11 05:00",
                "2021-08-11 06:00",
                "2021-08-11 07:00",
                "2021-08-11 08:00",
                "2021-08-11 09:00",
                "2021-08-11 10:00",
                "2021-08-11 11:00",
                "2021-08-11 12:00",
                "2021-08-11 13:00",
                "2021-08-11 14:00",
                "2021-08-11 15:00",
                "2021-08-11 16:00",
                "2021-08-11 17:00",
                "2021-08-11 18:00",
                "2021-08-11 19:00",
                "2021-08-11 20:00",
                "2021-08-11 21:00",
                "2021-08-11 22:00",
                "2021-08-11 23:00",
                "2021-08-12 00:00",
                "2021-08-12 01:00",
                "2021-08-12 02:00",
                "2021-08-12 03:00",
                "2021-08-12 04:00",
                "2021-08-12 05:00",
                "2021-08-12 06:00",
                "2021-08-12 07:00",
                "2021-08-12 08:00",
                "2021-08-12 09:00",
                "2021-08-12 10:00",
                "2021-08-12 11:00",
                "2021-08-12 12:00",
                "2021-08-12 13:00",
                "2021-08-12 14:00",
                "2021-08-12 15:00",
                "2021-08-12 16:00",
                "2021-08-12 17:00",
                "2021-08-12 18:00",
                "2021-08-12 19:00",
                "2021-08-12 20:00",
                "2021-08-12 21:00",
                "2021-08-12 22:00",
                "2021-08-12 23:00",
                "2021-08-13 00:00",
                "2021-08-13 01:00",
                "2021-08-13 02:00",
                "2021-08-13 03:00",
                "2021-08-13 04:00",
                "2021-08-13 05:00",
                "2021-08-13 06:00",
                "2021-08-13 07:00",
                "2021-08-13 08:00",
                "2021-08-13 09:00",
                "2021-08-13 10:00",
                "2021-08-13 11:00",
                "2021-08-13 12:00",
                "2021-08-13 13:00",
                "2021-08-13 14:00",
                "2021-08-13 15:00",
                "2021-08-13 16:00",
                "2021-08-13 17:00",
                "2021-08-13 18:00",
                "2021-08-13 19:00",
                "2021-08-13 20:00",
                "2021-08-13 21:00",
                "2021-08-13 22:00",
                "2021-08-13 23:00",
                "2021-08-14 00:00",
                "2021-08-14 01:00",
                "2021-08-14 02:00",
                "2021-08-14 03:00",
                "2021-08-14 04:00",
                "2021-08-14 05:00",
                "2021-08-14 06:00",
                "2021-08-14 07:00",
                "2021-08-14 08:00",
                "2021-08-14 09:00",
                "2021-08-14 10:00",
                "2021-08-14 11:00",
                "2021-08-14 12:00",
                "2021-08-14 13:00",
                "2021-08-14 14:00",
                "2021-08-14 15:00",
                "2021-08-14 16:00",
                "2021-08-14 17:00",
                "2021-08-14 18:00",
                "2021-08-14 19:00",
                "2021-08-14 20:00",
                "2021-08-14 21:00",
                "2021-08-14 22:00",
                "2021-08-14 23:00",
                "2021-08-15 23:00",
                "2021-08-17 03:00",
                "2021-08-17 04:00",
                "2021-08-17 13:00",
                "2021-08-17 14:00",
                "2021-08-17 15:00",
                "2021-08-17 16:00",
                "2021-08-17 17:00",
                "2021-08-17 18:00",
                "2021-08-17 19:00",
                "2021-08-17 20:00",
                "2021-08-17 21:00",
                "2021-08-17 22:00",
                "2021-08-17 23:00",
                "2021-08-18 00:00",
                "2021-08-18 01:00",
                "2021-08-18 03:00",
                "2021-08-18 04:00",
                "2021-08-18 05:00",
                "2021-08-18 06:00",
                "2021-08-18 07:00",
                "2021-08-18 08:00",
                "2021-08-18 09:00",
                "2021-08-18 10:00",
                "2021-08-18 11:00",
                "2021-08-18 12:00",
                "2021-08-18 13:00",
                "2021-08-18 14:00",
                "2021-08-18 15:00",
                "2021-08-18 16:00",
                "2021-08-18 17:00",
                "2021-08-18 18:00",
                "2021-08-18 19:00",
                "2021-08-18 20:00",
                "2021-08-18 21:00",
                "2021-08-18 22:00",
                "2021-08-18 23:00",
                "2021-08-19 00:00",
                "2021-08-19 01:00",
                "2021-08-19 02:00",
                "2021-08-19 03:00",
                "2021-08-19 04:00",
                "2021-08-19 05:00",
                "2021-08-19 06:00",
                "2021-08-19 07:00",
                "2021-08-19 08:00",
                "2021-08-19 09:00",
                "2021-08-19 10:00",
                "2021-08-19 11:00",
                "2021-08-19 12:00",
                "2021-08-19 13:00",
                "2021-08-19 14:00",
                "2021-08-19 15:00",
                "2021-08-19 16:00",
                "2021-08-19 17:00",
                "2021-08-19 18:00",
                "2021-08-19 19:00",
                "2021-08-19 20:00",
                "2021-08-19 21:00",
                "2021-08-19 22:00",
                "2021-08-19 23:00",
                "2021-08-20 00:00",
                "2021-08-20 01:00",
                "2021-08-20 02:00",
                "2021-08-20 03:00",
                "2021-08-20 04:00",
                "2021-08-20 05:00",
                "2021-08-20 06:00",
                "2021-08-20 07:00",
                "2021-08-20 08:00",
                "2021-08-20 09:00",
                "2021-08-20 10:00",
                "2021-08-20 11:00",
                "2021-08-20 12:00",
                "2021-08-20 16:00",
                "2021-08-20 19:00",
                "2021-08-20 20:00",
                "2021-08-20 21:00",
                "2021-08-20 22:00",
                "2021-08-20 23:00",
                "2021-08-21 00:00",
                "2021-08-21 01:00",
                "2021-08-21 02:00",
                "2021-08-21 03:00",
                "2021-08-21 04:00",
                "2021-08-21 05:00",
                "2021-08-21 06:00",
                "2021-08-21 07:00",
                "2021-08-21 08:00",
                "2021-08-21 09:00",
                "2021-08-21 10:00",
                "2021-08-21 11:00",
                "2021-08-21 12:00",
                "2021-08-21 13:00",
                "2021-08-21 14:00",
                "2021-08-21 15:00",
                "2021-08-21 16:00",
                "2021-08-21 17:00",
                "2021-08-21 18:00",
                "2021-08-21 19:00",
                "2021-08-21 20:00",
                "2021-08-21 21:00",
                "2021-08-21 22:00",
                "2021-08-21 23:00",
                "2021-08-22 00:00",
                "2021-08-22 01:00",
                "2021-08-22 02:00",
                "2021-08-22 03:00",
                "2021-08-22 04:00",
                "2021-08-22 05:00",
                "2021-08-22 06:00",
                "2021-08-22 07:00",
                "2021-08-22 08:00",
                "2021-08-22 09:00",
                "2021-08-22 10:00",
                "2021-08-22 11:00",
                "2021-08-22 12:00",
                "2021-08-22 13:00",
                "2021-08-22 14:00",
                "2021-08-22 15:00",
                "2021-08-22 16:00",
                "2021-08-22 17:00",
                "2021-08-22 18:00",
                "2021-08-22 19:00",
                "2021-08-22 20:00",
                "2021-08-22 21:00",
                "2021-08-22 22:00",
                "2021-08-22 23:00",
                "2021-08-23 00:00",
                "2021-08-23 01:00",
                "2021-08-23 02:00",
                "2021-08-23 03:00",
                "2021-08-23 04:00",
                "2021-08-23 05:00",
                "2021-08-23 06:00",
                "2021-08-23 07:00",
                "2021-08-23 08:00",
                "2021-08-23 09:00",
                "2021-08-23 10:00",
                "2021-08-23 11:00",
                "2021-08-23 12:00",
                "2021-08-23 13:00",
                "2021-08-23 14:00",
                "2021-08-23 15:00",
                "2021-08-23 16:00",
                "2021-08-23 17:00",
                "2021-08-23 18:00",
                "2021-08-23 19:00",
                "2021-08-23 20:00",
                "2021-08-23 21:00",
                "2021-08-23 22:00",
                "2021-08-23 23:00",
                "2021-08-24 00:00",
                "2021-08-24 01:00",
                "2021-08-24 02:00",
                "2021-08-24 03:00",
                "2021-08-24 04:00",
                "2021-08-24 05:00",
                "2021-08-24 06:00",
                "2021-08-24 07:00",
                "2021-08-24 08:00",
                "2021-08-24 09:00",
                "2021-08-24 10:00",
                "2021-08-24 11:00",
                "2021-08-24 12:00",
                "2021-08-24 13:00",
                "2021-08-24 14:00",
                "2021-08-24 15:00",
                "2021-08-24 16:00",
                "2021-08-24 17:00",
                "2021-08-24 18:00",
                "2021-08-24 19:00",
                "2021-08-24 20:00",
                "2021-08-24 21:00",
                "2021-08-24 22:00",
                "2021-08-24 23:00",
                "2021-08-25 00:00",
                "2021-08-25 01:00",
                "2021-08-25 02:00",
                "2021-08-25 03:00",
                "2021-08-25 04:00",
                "2021-08-25 05:00",
                "2021-08-25 06:00",
                "2021-08-25 07:00",
                "2021-08-25 08:00",
                "2021-08-25 09:00",
                "2021-08-25 10:00",
                "2021-08-25 11:00",
                "2021-08-25 12:00",
                "2021-08-25 13:00",
                "2021-08-25 14:00",
                "2021-08-25 15:00",
                "2021-08-25 16:00",
                "2021-08-25 17:00",
                "2021-08-25 18:00",
                "2021-08-25 19:00",
                "2021-08-25 20:00",
                "2021-08-25 21:00",
                "2021-08-25 22:00",
                "2021-08-25 23:00",
                "2021-08-26 00:00",
                "2021-08-26 01:00",
                "2021-08-26 02:00",
                "2021-08-26 03:00",
                "2021-08-26 04:00",
                "2021-08-26 05:00",
                "2021-08-26 06:00",
                "2021-08-26 07:00",
                "2021-08-26 08:00",
                "2021-08-26 09:00",
                "2021-08-26 10:00",
                "2021-08-26 11:00",
                "2021-08-26 12:00",
                "2021-08-26 13:00",
                "2021-08-26 14:00",

            ],
            y: [
                130.58,
                13.58,
                13.58,
                13.58,
                13.58,
                15.55,
                15.56,
                15.55,
                15.55,
                16.48,
                16.48,
                16.48,
                16.48,
                19.08,
                19.08,
                10, 0, 0,
                18.88,
                19.75,
                19.75,
                19.83,
                19.83,
                19.83,
                19.83,
                19.84,
                19.84,
                19.84,
                19.84,
                19.84,
                19.84,
                19.84,
                19.84,
                19.84,
                19.84,
                19.84,
                19.82,
                19.82,
                19.82,
                19.82,
                19.82,
                19.82,
                19.93,
                19.93,
                110.21,
                110.52,
                110.89,
                110.91,
                110.91,
                110.91,
                110.91,
                110.91,
                110.91,
                111.84,
                111.84,
                112.26,
                113.17,
                113.17,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                113.25,
                13.25,
                13.25,
                13.25,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                13.252,
                14.244,
                14.317,
                14.551,
                14.551,
                15.349,
                15.349,
                15.349,
                15.349,
                15.349,
                15.349,
                16.562,
                16.562,
                16.562,
                16.562,
                16.562,
                16.562,
                16.562,
                16.562,
                16.729,
                16.729,
                17.759,
                17.759,
                17.759,
                17.759,
                18.052,
                18.052,
                18.052,
                18.052,
                18.052,
                18.217,
                18.875,
                18.875,
                18.956,
                18.956,
                18.956,
                18.956,
                18.956,
                18.956,
                18.956,
                18.956,
                18.956,
                18.957,
                18.957,
                18.957,
                18.957,
                18.957,
                18.957,
                18.957,
                19.82,
                10.163,
                10.163,
                10.163,
                11.37,
                11.37,
                11.37,
                11.37,
                12.526,
                12.526,
                2.526,
                2.526,
                2.526,
                2.66,
                3.681,
                3.681,
                3.681,
                3.681,
                4.845,
                4.845,
                4.969,
                4.968,
                5.179,
                5.179,
                6.179,
                6.179,
                6.323,
                6.323,
                7.453,
                7.453,
                7.453,
                27.453,
                27.453,
                27.453,
                27.453,
                27.453,
                27.453,
                27.453,
                27.453,
                27.453,
                27.46,
                27.46,
                27.46,
                27.46,
                27.46,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.446,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.445,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.349,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.345,
                27.329,
                27.329,
                27.329,
                27.329,
                27.329,
                27.328,
                27.328,
                27.328,
                27.328,
                27.327,
                27.327,
                27.327,
                27.327,
                27.327,
                27.327,
                28.589,
                28.589,
                28.589,
                28.589,
                29.445,
                29.445,
                29.445,
                29.445,
                29.778,
                29.778,
                29.77,
                29.77,
                30.94,
                30.94,
                30.94,
                30.94,
                31.989,
                32.192,
                32.192,
                32.192,
                32.192,
                32.192,
                32.302,
                32.302,
                33.395,
                33.543,
                34.595,
                34.595,
                34.595,
                34.595,
                34.595,
                34.595,
                34.582,
                35.227,
                35.78,
                35.78,
                36.969,
                37.351,
                37.609,
                37.609,
                38.17,
                38.17,
                38.17,
                38.168,
                38.168,
                38.168,
                38.168,
                38.168,
                38.168,
                38.823,
                38.823,
                39.39,
                39.39,
                39.39,
                39.39,
                39.39,
                39.39,
                39.39,
                39.39,
                39.375,
                39.375,
                39.437,
                39.437,
                40.578,
                40.584,
                40.584,
                40.584,
                41.786,
                41.786,
                42.4,
                42.4,
                42.987,
                42.987,
                42.987,
                42.987,
                42.987,
                42.987,
                42.987,
                42.987,
                43.217,
                43.217,
                43.887,
                43.887,
                44.184,
                44.173,
                44.173,
                44.173,
                44.453,
                44.453,
                44.453,
                44.453,
                44.453,
                44.453,
                45.383,
                45.383,
                45.383,
                45.383,
                45.969,
                45.969,
                46.526,
                46.526,
                46.55,
                46.55,
                46.55,
                46.55,
                46.55,
                46.55,
                46.55,
                46.55,
                46.55,
                46.55,
                46.55,
                46.55,
                46.55,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                57.397,
                57.432,
                57.432,
                58.606,
                59.456,
                59.456,
                59.456,
                59.456,
                59.456,
                59.456,
                59.456,
                59.456,
                59.519,
                59.818,
                59.818,
                59.818,
                59.818,
                59.818,
                59.818,
                61.014,
                61.014,
                61.014,
                61.014,
                61.014,
                61.014,
                61.014,
                61.014,
                61.014,
                61.696,
                62.21,
                62.21,
                63.41,
                63.533,
                64.09,
                64.09,
                64.09,
                64.09,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.085,
                64.083,
                64.594,
                64.594,
                64.594,
                64.602,
                65.805,
                65.808,
                65.808,
                66.289,
                66.844,
                67.007,
                67.007,
                67.007,
                67.007,
                67.708,
                67.708,
                69.388,
                69.439,
                70.162,
                70.162,
                70.597,
                70.597,
                70.597,
                70.597,
                70.606,
                71.196,
                71.791,
                71.791,
                71.774,
                71.774,
                72.928,
                72.928,
                72.982,
                72.982,
                73.803,
                73.803,
                74.182,
                74.182,
                74.182,
                74.182,
                74.57,
                75.38,
                75.38,
                75.38,
                75.38,
                75.38,
                75.38,
                75.38,
                75.35,
                75.35,
                75.35,
                75.35,
                75.354,
                75.468,
                75.468,
                75.468,
                76.68,
                76.658,
                76.658,
                76.658,
                76.658,
                77.71,
                77.71,
                77.71,
                77.71,
                77.882,
                77.861,
                77.861,
                77.861,
                77.861,
                77.861,
                77.861,
                77.838,
                77.838,
                77.838,
                77.838,
                77.838,
                77.838,
                77.917,
                77.917,
                77.917,
                77.917,
                77.917,
                77.917,
                77.917,
                77.917,
                77.917,
                77.917,
                79.147,
                79.147,
                79.7,
                79.7,
                79.838,
                79.838,
                79.838,
                79.838,
                80.342,
                80.342,
                80.342,
                80.342,
                81.349,
                81.46,
                81.46,
                81.46,
                81.46,
                81.46,
                81.46,
                81.46,
                82.671,
                82.674,
                82.674,
                82.674,
                82.674,
                82.674,
                82.674,
                82.674,
                82.674,
                82.674,
                82.643,
                82.643,
                82.845,
                82.845,
                83.371,
                83.371,
                83.856,
                83.856,
                83.856,
                83.857,
                84.428,
                85.064,
                85.064,
                85.066,
                85.066,
                85.066,
                85.066,
                85.066,
                85.066,
                85.066,
                85.066,
                85.066,
                85.066,
                85.066,
                85.066,
                85.066,
                85.066,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.598,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.669,
                88.698,
                88.698,
                88.698,
                88.698,
                88.836,
                88.836,
                88.92,
                89.243,
                89.814,
                89.814,
                89.814,
                93.45,
                94.272,
                94.272,
                94.272,
                94.272,
                94.272,
                94.283,
                94.57,
                94.57,
                94.625,
                94.625,
                94.625,
                94.625,
                95.889,
                95.869,
                95.871,
                95.871,
                95.871,
                95.871,
                96.688,
                96.688,
                97.092,
                97.092,
                97.092,
                97.092,
                97.092,
                97.092,
                97.051,
                97.051,
                98.233,
                98.233,
                98.233,
                98.233,
                98.233,
                98.233,
                98.233,
                98.233,
                99.453,
                99.464,
                99.464,
                99.464,
                99.464,
                99.455,
                100.595,
                100.595,
                100.683,
                100.666,
                101.057,
                101.057,
                101.353,
                101.419,
                101.419,
                101.419,
                101.419,
                101.419,
                101.419,
                101.419,
                101.419,
                101.419,
                101.419,
                101.419,
                101.859,
                101.859,
                101.859,
                101.859,
                101.859,
                101.859,
                101.859,
                101.859,
                101.859,
                102.045,
                103.095,
                103.095,
                103.267,
                104.287,
                104.287,
                104.287,
                105.49,
                105.49,
                105.49,
                105.49,
                105.49,
                105.49,
                105.625,
                105.625,
                106.655,
                107.426,
                107.872,
                107.872,
                107.872,
                107.872,
                107.872,
                107.872,
                109.1,
                109.084,
                109.084,
                109.084,
                109.084,
                109.084,
                109.069,
                109.069,
                110.282,
                110.285,
                110.285,
                110.285,
                111.484,
                111.484,
                111.484,
                111.484,
                112.648,
                112.648,
                112.648,
                112.648,
                112.648,
                112.648,
                112.648,
                112.648,
                113.85,
                113.85,
                113.85,
                113.85,
                115.063,
                115.063,
                115.624,
                115.624,
                116.263,
                116.263,
                116.263,
                116.263,
                117.461,
                117.461,
                117.461,
                117.461,
                118.66,
                118.647,
                118.647,
                118.647,
                118.647,
                118.647,
                119.55,
                119.55,
                119.848,
                119.848,
                119.848,
                119.848,
                120.937,
                121.053,
                121.053,
                121.053,
                121.053,
                122.043,
                122.263,
                122.263,
                122.925,
                123.443,
                123.443,
                123.443,
                123.443,
                124.087,
                124.633,
                124.633,
                124.633,
                124.633,
                124.633,
                124.633,
                124.633,
                124.633,
                124.633,
                124.633,
                124.633,
                124.993,
                125.836,
                125.836,
                127.045,
                127.036,
                127.036,
                127.036,
                128.233,
                128.233,
                128.233,
                128.233,
                128.233,
                128.233,
                129.441,
                129.441,
                130.064,
                130.626,
                130.626,
                130.626,
                131.837,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                131.82,
                133.209,
                133.209,
                134.216,
                134.216,
                134.216,
                134.216,
                134.216,
                134.216,
                134.216,
                134.216,
                134.216,
                134.216,
                134.216,
                134.216,
                134.216,
                134.184,
                134.184,
                134.184,
                134.184,
                134.184,
                134.184,
                134.184,
                134.184,
                34.184,
                34.182,
                34.182,
                34.182,
                34.182,
                34.182,
                34.182,
                34.182,
                34.182,
                34.182,
                34.182,
                34.292,
                34.292,
                35.403,
                35.403,
                35.403,
                35.403,
                35.403,
                35.516,
                36.217,
                36.217,
                36.615,
                36.604,
                36.604,
                36.604,
                36.604,
                36.604,
                36.604,
                36.604,
                36.797,
                37.324,
                37.775,
                37.775,
                37.775,
                37.775,
                38.092,
                38.092,
                39,
                39,
                39,
                39,
                39.239,
                40.212,
                40.212,
                40.212,
                40.212,
                40.192,
                40.192,
                40.192,
                40.25,
                40.37,
                40.37,
                40.37,
                40.916,
                41.38,
                41.379,
                41.379,
                41.373,
                41.373,
                41.373,
                41.373,
                42.625,
                42.625,
                42.625,
                42.625,
                43.232,
                43.803,
                43.803,
                43.803,
                44.923,
                44.977,
                44.977,
                44.977,
                46.176,
                46.176,
                46.176,
                46.176,
                47.393,
                50.212,
                50.212,
                50.212,
                50.212,
                50.192,
                50.192,
                50.192,
                50.25,
                50.37,
                50.37,
                50.37,
                50.916,
                51.38,
                51.379,
                51.379,
                51.373,
                51.373,
                51.373,
                51.373,
                52.625,
                52.625,
                52.625,
                52.625,
                53.232,
                53.803,
                53.803,
                53.803,
                54.923,
                54.977,
                54.977,
                54.977,
                56.176,
                56.176,
                56.176,
                56.176,
                57.393,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                61.82,
                63.209,
                63.209,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.216,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.184,
                64.182,
                64.182,
                64.182,
                64.182,
                64.182,
                64.182,
                64.182,
                64.182,
                64.182,
                64.182,
                64.292,
                64.292,
                65.403,
                65.403,
                65.403,
                65.403,
                65.403,
                65.516,
                66.217,
                66.217,
                66.615,
                66.604,
                66.604,
                66.604,
                66.604,
                66.604,
                66.604,
                66.604,
                66.797,
                67.324,
                67.775,
                67.775,
                67.775,
                67.775,
                68.092,
                68.092,
                69,
                69,
                69,
                69,
                69.239,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                71.82,
                73.209,
                73.209,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.216,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.184,
                74.182,
                74.182,
                74.182,
                74.182,
                74.182,
                74.182,
                74.182,
                74.182,
                74.182,
                74.182,
                74.292,
                74.292,
                75.403,
                75.403,
                75.403,
                75.403,
                75.403,
                75.516,
                76.217,
                76.217,
                76.615,
                76.604,
                76.604,
                76.604,
                76.604,
                76.604,
                76.604,
                76.604,
                76.797,
                77.324,
                77.775,
                77.775,
                77.775,
                77.775,
                78.092,
                78.092,
                79,
                79,
                79,
                79,
                79.239,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                81.82,
                83.209,
                83.209,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                84.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.216,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.184,
                184.182,
                184.182,
                184.182,
                184.182,
                184.182,
                184.182,
                184.182,
                184.182,
                184.182,
                0,
                0,
                184.292,
                185.403,
                185.403,
                185.403,
                185.403,
                185.403,
                185.516,
                186.217,
                186.217,
                186.615,
                186.604,
                186.604,
                186.604,
                186.604,
                186.604,
                186.604,
                186.604,
                186.797,
                187.324,
                187.775,
                187.775,
                187.775,
                187.775,
                188.092,
                188.092,
                189,
                189,
                189,
                189,
                189.239,
            ]
        }
        if (this.props.donnees !== undefined) {
            data.x = [...convertiUtsToDate(this.props.donnees[0].x)];
            data.y = [...this.props.donnees[0].y];
        }
        let dataParJourX = [];
        let dataParJourY = [];
        let dataParJourProgressionY = [];
//Formatage de données
        let longueurListeLaPlusCourte;
        if (data.x.length <= data.y.length) {
            longueurListeLaPlusCourte = data.x.length
        } else {
            longueurListeLaPlusCourte = data.y.length
        }
        let progressionJournaliere = Math.round(data.y[0]); //valeur par défaut (commence le forage à l'état initiale 0)

        let premierJourDeLaSemaine = data.x[0];
        let dataParSemaineX = [];
        let dataParSemaineY = [];
        let dataParSemaineProgressionY = [];
        let progressionHebdomadaire = Math.round(data.y[0]); //valeur par défaut (commence le forage à l'état initiale 0)

        let dataParMoisX = [];
        let dataParMoisY = [];
        let dataParMoisProgressionY = [];
        let progressionMensuelle = Math.round(data.y[0]); //valeur par défaut (commence le forage à l'état initiale 0)
        for (let i = 0; i < longueurListeLaPlusCourte; i++) {
            if (i + 1 < longueurListeLaPlusCourte) {
                //Formatage de données -> données journaliére
                const dateI = data.x[i].split(" ")[0];
                const dateJ = data.x[i + 1].split(" ")[0];
                if (dateI !== dateJ && Math.round(data.y[i]) > progressionJournaliere) {
                    //date en omettant l'heure
                    dataParJourX.push(dateI)//date sans les heures
                    //données brute (cumulative) par jour
                    dataParJourY.push(Math.round(data.y[i]))//arrondi à l'entier
                    //données par jour relative au jour précédent
                    dataParJourProgressionY.push(Math.round(data.y[i]) - progressionJournaliere)
                    progressionJournaliere = Math.round(data.y[i])
                }

                //Formatage de données -> données Hebdomadaires
                const jourI = data.x[i].split(" ")[0]
                const semaineI = getWeekNumberForPlotlyDate(jourI);
                const semaineJ = getWeekNumberForPlotlyDate(data.x[i + 1].split(" ")[0]);
                if (semaineI !== semaineJ && Math.round(data.y[i]) > progressionHebdomadaire) {
                    //date en omettant l'heure
                    // dataParSemaineX.push("Semaine "+semaineI)//par semaine
                    dataParSemaineX.push(premierJourDeLaSemaine) //date sans les heures
                    //données brute (cumulative) par semaine
                    dataParSemaineY.push(Math.round(data.y[i]))//arrondi à l'entier
                    //données par mois relative au jour précédent
                    dataParSemaineProgressionY.push(Math.round(data.y[i]) - progressionHebdomadaire)
                    progressionHebdomadaire = Math.round(data.y[i])

                    premierJourDeLaSemaine = data.x[i + 1].split(" ")[0]
                }

                //Formatage de données -> données mensuelles
                const moisI = data.x[i].split(" ")[0].substring(0, 7);
                const moisJ = data.x[i + 1].split(" ")[0].substring(0, 7);
                if (moisI !== moisJ && Math.round(data.y[i]) > progressionMensuelle) {
                    //date en omettant l'heure
                    dataParMoisX.push(moisI + "-01")//date sans les heures ni les jours
                    //données brute (cumulative) par mois
                    dataParMoisY.push(Math.round(data.y[i]))//arrondi à l'entier
                    //données par mois relative au jour précédent
                    dataParMoisProgressionY.push(Math.round(data.y[i]) - progressionMensuelle)
                    progressionMensuelle = Math.round(data.y[i])
                }
            } else {
                dataParJourX.push(data.x[i].split(" ")[0])//date sans les heures
                dataParJourY.push(Math.round(data.y[i]))
                dataParJourProgressionY.push(Math.round(data.y[i] - progressionJournaliere))

                dataParSemaineX.push(premierJourDeLaSemaine.split(" ")[0])//date sans les heures
                dataParSemaineY.push(Math.round(data.y[i]))
                dataParSemaineProgressionY.push(Math.round(data.y[i] - progressionHebdomadaire))

                dataParMoisX.push(data.x[i].split(" ")[0].substring(0, 7) + "-01")//date sans les heures ni les jours
                dataParMoisY.push(Math.round(data.y[i]))
                dataParMoisProgressionY.push(Math.round(data.y[i] - progressionMensuelle))
            }
        }
        const dataJournalier = {
            x: dataParJourX,
            y: dataParJourY
        }
        const dataProgressionJournalier = {
            x: dataParJourX,
            y: dataParJourProgressionY
        }
        const dataHebdomadaire = {
            x: dataParSemaineX,
            y: dataParSemaineY
        }
        const dataProgressionHebdomadaire = {
            x: dataParSemaineX,
            y: dataParSemaineProgressionY
        }
        const dataMensuel = {
            x: dataParMoisX,
            y: dataParMoisY,
        }
        const dataProgressionMensuel = {
            x: dataParMoisX,
            y: dataParMoisProgressionY
        }

        let courbeHistogrammeJournalier = {
            ...dataProgressionJournalier,
            yaxis: 'y4',
            type: 'bar',
            name: 'HistogrammeJournalier',
            marker: {
                color: 'rgb(238, 173, 1)',//couleur des histogramme
                opacity: 0.7,
            }
        };
        let courbeJour = {
            ...dataJournalier,
            type: 'scatter',
            name: 'ProgressionJournalière',
            fill: 'tozeroy',
            stackgroup: 'one',
            fillcolor: "rgba(180, 180, 180, 0.3)", // couleur de l'aire sous la courbe
            marker: {
                color: 'rgb(180, 180, 180)', // couleur de la courbe
                opacity: 0.7,
            }
        };
        let courbeHistogrammeHebdomadaire = {
            ...dataProgressionHebdomadaire,
            xaxis: 'x2',
            yaxis: 'y5',
            type: 'bar',
            name: 'HistogrammeHebdomadaire',
            marker: {
                color: 'rgb(238, 173, 1)',
                opacity: 0.7,
            }
        };
        let courbeSemaine = {
            ...dataHebdomadaire,
            xaxis: 'x2',
            yaxis: 'y2',
            type: 'scatter',
            name: 'ProgressionHebdomadaire',
            fill: 'tozeroy',
            stackgroup: 'one',
            fillcolor: "rgba(180, 180, 180, 0.3)", // couleur de l'aire sous la courbe
            marker: {
                color: 'rgb(180, 180, 180)', // couleur de la courbe
                opacity: 0.7,
            }
        };
        let courbeHistogrammeMensuel = {
            ...dataProgressionMensuel,
            xaxis: 'x3',
            yaxis: 'y6',
            type: 'bar',
            name: 'HistogrammeMensuel',
            marker: {
                color: 'rgb(238, 173, 1)',
                opacity: 0.7,
            }
        };
        let courbeMois = {
            ...dataMensuel,
            xaxis: 'x3',
            yaxis: 'y3',
            type: 'scatter',
            name: 'ProgressionMensuelle',
            fill: 'tozeroy',
            stackgroup: 'one',
            fillcolor: "rgba(180, 180, 180, 0.3)", // couleur de l'aire sous la courbe
            marker: {
                color: 'rgb(180, 180, 180)', // couleur de la courbe
                opacity: 0.7,
            }
        };

        const distanceFinale = this.props.viewobj_json_content === undefined || this.props.viewobj_json_content.distanceFinale === undefined ? 2408 : this.props.viewobj_json_content.distanceFinale;
        const distanceParcourus = Math.max(...dataJournalier.y);

        data = [
            courbeHistogrammeJournalier,
            courbeJour,
            courbeHistogrammeHebdomadaire,
            courbeSemaine,
            courbeHistogrammeMensuel,
            courbeMois,
        ];

        let layout = {
            title: {
                text: this.props.configuration.title + '<br>' + this.props.configuration.subtitle,
                font: {
                    // family: 'Courier New, monospace',
                    size: 24
                },
            },
            yaxis1: {domain: [0, 0.3], anchor: 'x1', side: "right"},
            yaxis4: {domain: [0, 0.3], anchor: 'x1', visible: false, overlaying: 'y1'},
            xaxis1: {
                domain: [0, 0.97],
                anchor: 'y1',
                type: 'date',
                nticks: dateDiff(data[0].x[0], data[0].x[data[0].x.length - 1]) + 2,
                tickformat: '%b %d',
            },

            yaxis2: {domain: [0.34, 0.6], anchor: 'x2', side: "right"},
            yaxis5: {domain: [0.34, 0.6], anchor: 'x2', visible: false, overlaying: 'y2'},
            xaxis2: {
                domain: [0, 0.97],
                anchor: 'y2',
                type: 'date',
                tickformat: checkMot("semaine_short") + '%W',
                tickmode: "auto",
                nticks: data[2].x.length + 1,
            },

            yaxis3: {domain: [0.74, 0.97], anchor: 'x3', side: "right"},
            yaxis6: {domain: [0.74, 1], anchor: 'x3', visible: false, overlaying: 'y3'},
            xaxis3: {
                domain: [0, 0.97],
                anchor: 'y3',
                type: 'date',
                tickformat: '%B',
                tickmode: "auto",
                // nticks: data[4].x.length + 2
                nticks: data[4].x.length + 2
            },
            annotations: [
                {
                    text: checkMot("mensuel"),
                    font: {
                        size: 16,
                        color: 'rgb(180, 180, 180)',
                    },
                    showarrow: false,
                    align: 'right',
                    xanchor: 'left',
                    x: .0,
                    y: 1.05,
                    xref: 'paper',
                    yref: 'paper',
                },
                {
                    text: checkMot("hebdomadaire"),
                    font: {
                        size: 16,
                        color: 'rgb(180, 180, 180)',
                    },
                    showarrow: false,
                    align: 'right',
                    xanchor: 'left',
                    x: .0,
                    y: .65,
                    xref: 'paper',
                    yref: 'paper',
                },
                {
                    text: checkMot("journalier"),
                    font: {
                        size: 16,
                        color: 'rgb(180, 180, 180)',
                    },
                    showarrow: false,
                    align: 'right',
                    xanchor: 'left',
                    x: 0,
                    y: .3,
                    xref: 'paper',
                    yref: 'paper',
                }

            ]
        };
        this.setState({
            data: data,
            layout: layout,
            dataJournalier: dataJournalier,
            dataProgressionJournalier: dataProgressionJournalier,
            dataHebdomadaire: dataHebdomadaire,
            dataProgressionHebdomadaire: dataProgressionHebdomadaire,
            dataMensuel: dataMensuel,
            dataProgressionMensuel: dataProgressionMensuel,
            courbeHistogrammeJournalier: courbeHistogrammeJournalier,
            courbeJour: courbeJour,
            courbeHistogrammeHebdomadaire: courbeHistogrammeHebdomadaire,
            courbeSemaine: courbeSemaine,
            courbeHistogrammeMensuel: courbeHistogrammeMensuel,
            courbeMois: courbeMois,
            // gaugeDeProgression: gaugeDeProgression,
            // table: table,
            distanceFinale: distanceFinale,
            distanceParcourus: distanceParcourus,
        })

    }

    render() {
        return <BibliothequePlotly
            data={
                [...this.state.data]
            }
            layout={{
                ...this.state.layout,
                responsive: true,
                showlegend: false,
                ...this.props.viewobj_json_content
            }}
            dimensionWidth={this.props.dimensionWidth}
            dimensionHeight={this.props.dimensionHeight}
            viewobj_json_content={this.props.viewobj_json_content}

            onClick={(data) => {
                let pn = '',
                    tn = '',
                    xdata = '',
                    ydata = '',
                    xAxisNum = '';

                for (let i = 0; i < data.points.length; i++) {
                    pn = data.points[i].pointNumber;
                    tn = data.points[i].curveNumber;
                    xdata = data.points[i].x;
                    ydata = data.points[i].y;
                    xAxisNum = data.points[i].data.xaxis;
                }
                this.setState({
                    dateSelectionne: xdata,
                    valeurSelectionne: ydata,
                    xAxisSelectionne: xAxisNum,
                    indSelectionne: pn
                })
                let dateSelectionne = xdata
                let xAxisSelectionne = xAxisNum

                let prevDateSelectionne = this.state.prevDateSelectionne;
                let prevXAxisSelectionne = this.state.prevXAxisSelectionne;

                let dataProgressionJournalier = this.state.dataProgressionJournalier;
                let courbeHistogrammeJournalier = this.state.courbeHistogrammeJournalier;
                let courbeSemaine = this.state.courbeSemaine;
                let courbeHistogrammeHebdomadaire = this.state.courbeHistogrammeHebdomadaire;
                let courbeMois = this.state.courbeMois;
                let courbeHistogrammeMensuel = this.state.courbeHistogrammeMensuel;
                let dataProgressionHebdomadaire = this.state.dataProgressionHebdomadaire;
                let courbeJour = this.state.courbeJour;
                let dataProgressionMensuel = this.state.dataProgressionMensuel;

                let dataRetrievedLater = [...this.state.data]
                dataRetrievedLater[0] = {...courbeHistogrammeJournalier};
                dataRetrievedLater[1] = {...courbeJour};
                dataRetrievedLater[2] = {...courbeHistogrammeHebdomadaire};
                dataRetrievedLater[3] = {...courbeSemaine};
                dataRetrievedLater[4] = {...courbeHistogrammeMensuel};
                dataRetrievedLater[5] = {...courbeMois};
                if (prevDateSelectionne === dateSelectionne && prevXAxisSelectionne === xAxisSelectionne) {
                    dateSelectionne = "";
                    xAxisSelectionne = "";
                }
                    // else if(prevXAxisSelectionne!==xAxisSelectionne && prevXAxisSelectionne!==""){
                    //   this.setState({prevDateSelectionne:"", prevXAxisSelectionne:""});
                // }
                else {
                    let filtreCourbeJourX = [];
                    let filtreCourbeJourY = [];
                    let filtreCourbeHistogrammeJournalierY = [];
                    let filtreCourbeSemaineX = [];
                    let filtreCourbeSemaineY = [];
                    let filtreCourbeHistogrammeHebdomadaireY = [];
                    let filtreCourbeMoisX = [];
                    let filtreCourbeMoisY = [];
                    let filtreCourbeHistogrammeMensuelY = [];
                    switch (xAxisSelectionne) {
                        case undefined:
                            //Modification des données lors de la sélection d'un jour
                            let surbrillanceJourSelectionne = new Array(dataProgressionJournalier.x.length).fill('rgba(238, 173, 1, 0.3)')
                            surbrillanceJourSelectionne[dataProgressionJournalier.x.indexOf(dateSelectionne)] = 'rgba(238, 173, 1, 0.7)'
                            // modification de la couleur du jour sélectionné
                            dataRetrievedLater[0] = {
                                ...courbeHistogrammeJournalier,
                                'marker': {
                                    color: [...surbrillanceJourSelectionne],//couleur des histogramme
                                }
                            }
                            filtreCourbeSemaineX = [];
                            filtreCourbeSemaineY = [];
                            filtreCourbeHistogrammeHebdomadaireY = [];
                            const semaineDuJourSelectionne = getWeekNumberForPlotlyDate(dateSelectionne)
                            const anneeDuJourSelectionne = dateSelectionne.substring(0, 3) //et année
                            for (let indDateX = 0; indDateX < courbeSemaine.x.length; indDateX++) {
                                if (getWeekNumberForPlotlyDate(courbeSemaine.x[indDateX]) === semaineDuJourSelectionne && courbeSemaine.x[indDateX].substring(0, 3) === anneeDuJourSelectionne) {
                                    filtreCourbeSemaineX.push(courbeSemaine.x[indDateX])
                                    filtreCourbeSemaineY.push(courbeSemaine.y[indDateX])
                                    filtreCourbeHistogrammeHebdomadaireY.push(courbeHistogrammeHebdomadaire.y[indDateX])
                                }
                            }
                            // filtreCourbeMois
                            dataRetrievedLater[2] = {
                                ...courbeHistogrammeHebdomadaire,
                                x: filtreCourbeSemaineX,
                                y: filtreCourbeHistogrammeHebdomadaireY,
                            }
                            dataRetrievedLater[3] = {
                                ...courbeSemaine,
                                x: filtreCourbeSemaineX,
                                y: filtreCourbeSemaineY,
                            }
                            filtreCourbeMoisX = [];
                            filtreCourbeMoisY = [];
                            filtreCourbeHistogrammeMensuelY = [];
                            const moisDuJourSelectionne = dateSelectionne.substring(0, 7) //et année
                            for (let indDateX = 0; indDateX < courbeMois.x.length; indDateX++) {
                                if (courbeMois.x[indDateX].substring(0, 7) === moisDuJourSelectionne) {
                                    filtreCourbeMoisX.push(courbeMois.x[indDateX])
                                    filtreCourbeMoisY.push(courbeMois.y[indDateX])
                                    filtreCourbeHistogrammeMensuelY.push(courbeHistogrammeMensuel.y[indDateX])
                                }
                            }
                            dataRetrievedLater[4] = {
                                ...courbeMois,
                                x: filtreCourbeMoisX,
                                y: filtreCourbeMoisY,
                            }
                            dataRetrievedLater[5] = {
                                ...courbeHistogrammeMensuel,
                                x: filtreCourbeMoisX,
                                y: filtreCourbeHistogrammeMensuelY,
                            }
                            break;

                        case "x2":
                            //Modification des données lors de la sélection d'une semaine
                            let surbrillanceSemaineSelectionne = new Array(dataProgressionHebdomadaire.x.length).fill('rgba(238, 173, 1, 0.3)')
                            surbrillanceSemaineSelectionne[dataProgressionHebdomadaire.x.indexOf(dateSelectionne)] = 'rgba(238, 173, 1, 0.9)'
                            // modification de la couleur du semaine sélectionnée
                            dataRetrievedLater[2] = {
                                ...courbeHistogrammeHebdomadaire,
                                'marker': {
                                    color: [...surbrillanceSemaineSelectionne],//couleur des histogramme
                                }
                            }
                            //filtre des jour après sélection d'une semaine
                            filtreCourbeJourX = [];
                            filtreCourbeJourY = [];
                            filtreCourbeHistogrammeJournalierY = [];
                            const semaineSelectionne = getWeekNumberForPlotlyDate(dateSelectionne)
                            const anneeDeLaSemaineSelectionne = dateSelectionne.substring(0, 3)
                            for (let indDateX = 0; indDateX < courbeJour.x.length; indDateX++) {
                                if (getWeekNumberForPlotlyDate(courbeJour.x[indDateX]) === semaineSelectionne && courbeJour.x[indDateX].substring(0, 3) === anneeDeLaSemaineSelectionne) {
                                    filtreCourbeJourX.push(courbeJour.x[indDateX])
                                    filtreCourbeJourY.push(courbeJour.y[indDateX])
                                    filtreCourbeHistogrammeJournalierY.push(courbeHistogrammeJournalier.y[indDateX])
                                }
                            }
                            // filtreCourbeMois
                            dataRetrievedLater[0] = {
                                ...courbeHistogrammeJournalier,
                                x: filtreCourbeJourX,
                                y: filtreCourbeHistogrammeJournalierY,
                            }
                            dataRetrievedLater[1] = {
                                ...courbeJour,
                                x: filtreCourbeJourX,
                                y: filtreCourbeJourY,
                            }

                            filtreCourbeMoisX = [];
                            filtreCourbeMoisY = [];
                            filtreCourbeHistogrammeMensuelY = [];
                            const moisDelaSemaineSelectionne = dateSelectionne.substring(0, 7)
                            for (let indDateX = 0; indDateX < courbeMois.x.length; indDateX++) {
                                if (courbeMois.x[indDateX].substring(0, 7) === moisDelaSemaineSelectionne) {
                                    filtreCourbeMoisX.push(courbeMois.x[indDateX])
                                    filtreCourbeMoisY.push(courbeMois.y[indDateX])
                                    filtreCourbeHistogrammeMensuelY.push(courbeHistogrammeMensuel.y[indDateX])
                                }
                            }
                            dataRetrievedLater[4] = {
                                ...courbeMois,
                                x: filtreCourbeMoisX,
                                y: filtreCourbeMoisY,
                            }
                            dataRetrievedLater[5] = {
                                ...courbeHistogrammeMensuel,
                                x: filtreCourbeMoisX,
                                y: filtreCourbeHistogrammeMensuelY,
                            }
                            break;

                        case "x3":
                            //Modification des données lors de la sélection d'un mois
                            let surbrillanceMoisSelectionne = new Array(dataProgressionMensuel.x.length).fill('rgba(238, 173, 1, 0.3)')

                            // if (xAxisSelectionne === prevXAxisSelectionne) {
                            //     surbrillanceMoisSelectionne[indSelectionne] = 'rgba(238, 173, 1, 0.9)'
                            // } else {
                            //cas où les index ne correspondent plus si on change de graphe
                            surbrillanceMoisSelectionne[dataProgressionMensuel.x.indexOf(dateSelectionne)] = 'rgba(238, 173, 1, 0.9)'
                            // }
                            // modification de la couleur du mois sélectionné
                            dataRetrievedLater[4] = {
                                ...courbeHistogrammeMensuel,
                                'marker': {
                                    color: [...surbrillanceMoisSelectionne],//couleur des histogramme
                                }
                            }
                            //filtre des jour après sélection d'une semaine
                            filtreCourbeJourX = [];
                            filtreCourbeJourY = [];
                            filtreCourbeHistogrammeJournalierY = [];
                            const moisSelectionne = dateSelectionne.substring(0, 7)
                            // const moisSelectionne = getWeekNumberForPlotlyDate(dateSelectionne)
                            for (let indDateX = 0; indDateX < courbeJour.x.length; indDateX++) {
                                if (courbeJour.x[indDateX].substring(0, 7) === moisSelectionne) {
                                    filtreCourbeJourX.push(courbeJour.x[indDateX])
                                    filtreCourbeJourY.push(courbeJour.y[indDateX])
                                    filtreCourbeHistogrammeJournalierY.push(courbeHistogrammeJournalier.y[indDateX])
                                }
                            }
                            // filtreCourbeJour
                            dataRetrievedLater[0] = {
                                ...courbeHistogrammeJournalier,
                                x: filtreCourbeJourX,
                                y: filtreCourbeHistogrammeJournalierY,
                            }
                            dataRetrievedLater[1] = {
                                ...courbeJour,
                                x: filtreCourbeJourX,
                                y: filtreCourbeJourY,
                            }

                            filtreCourbeSemaineX = [];
                            filtreCourbeSemaineY = [];
                            filtreCourbeHistogrammeHebdomadaireY = [];
                            for (let indDateX = 0; indDateX < courbeSemaine.x.length; indDateX++) {
                                if (courbeSemaine.x[indDateX].substring(0, 7) === moisSelectionne) {
                                    filtreCourbeSemaineX.push(courbeSemaine.x[indDateX])
                                    filtreCourbeSemaineY.push(courbeSemaine.y[indDateX])
                                    filtreCourbeHistogrammeHebdomadaireY.push(courbeHistogrammeHebdomadaire.y[indDateX])
                                }
                            }
                            dataRetrievedLater[2] = {
                                ...courbeSemaine,
                                x: filtreCourbeSemaineX,
                                y: filtreCourbeSemaineY,
                            }
                            dataRetrievedLater[3] = {
                                ...courbeHistogrammeHebdomadaire,
                                x: filtreCourbeSemaineX,
                                y: filtreCourbeHistogrammeHebdomadaireY,
                            }
                            break;
                    }
                }
                let ntickJour = dateDiff(dataRetrievedLater[0].x[0], dataRetrievedLater[0].x[dataRetrievedLater[0].x.length - 1]) + 2; //nombre de graduations nommées

                let newAxisLayout = {

                    yaxis1: {domain: [0, 0.3], anchor: 'x1', side: "right"},
                    yaxis4: {domain: [0, 0.3], anchor: 'x1', visible: false, overlaying: 'y1'},
                    xaxis1: {
                        domain: [0, 0.97],
                        anchor: 'y1',
                        type: 'date',
                        tickformat: dataRetrievedLater[0].x.length > 7 ? '%b %d' : '%A %d',
                        nticks: ntickJour
                    },

                    yaxis2: {domain: [0.34, 0.6], anchor: 'x2', side: "right"},
                    yaxis5: {domain: [0.34, 0.6], anchor: 'x2', visible: false, overlaying: 'y2'},
                    xaxis2: {
                        domain: [0, 0.97],
                        anchor: 'y2',
                        type: 'date',
                        tickformat: checkMot("semaine_short") + '%W',
                        tickmode: "auto",
                        nticks: dataRetrievedLater[2].x.length + 1,
                    },

                    yaxis3: {domain: [0.74, 0.97], anchor: 'x3', side: "right"},
                    yaxis6: {domain: [0.74, 1], anchor: 'x3', visible: false, overlaying: 'y3'},
                    xaxis3: {
                        domain: [0, 0.97],
                        anchor: 'y3',
                        type: 'date',
                        tickformat: '%B',
                        tickmode: "auto",
                        nticks: dataRetrievedLater[4].x.length
                    },
                }

                this.setState({
                    data: [...dataRetrievedLater],
                    prevDateSelectionne: dateSelectionne,
                    prevXAxisSelectionne: xAxisSelectionne,
                    layout: {
                        ...this.state.layout,
                        ...newAxisLayout,
                    },
                });

            }}
        />
    }


}

export default GraphesAvancementJourSemaineMois;
